import { Component, OnInit } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient ,HttpHeaders,HttpErrorResponse  } from '@angular/common/http';
import { formatDate } from "@angular/common";
import { Location } from '@angular/common';


type UserFields = "email" | "password";
type FormErrors = { [u in UserFields]: string };
import { environment } from 'src/environments/environment';
import { UploadService } from './upload.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

	val:any;
	sub:any;
	customers:any;
	isShown: boolean = false ;	
	submitted: boolean = false;
	submittedSeller: boolean = false;
	cmpisShown: boolean = false ;	
	cmpsubmitted: boolean = false;	
	actMsg: boolean=false;
	poMessage: boolean;	
	poMessage1: boolean;	
	msg:any;
	msgSeller:any;
	cmpmsg:any;
	dst_date_from:any;
	dst_date_to:any;
	headers = new HttpHeaders({ 'Content-Type': 'application/json','API_KEY':'20e20m06i16e14r31p','Version':'1.0' });    	 
	options = { headers: this.headers };	
	username:any;
	password:any;
	token:any;
	opt:any;
	
	iv:any;
	siv:any;
	registerMsg:any;
	
	public newUser = false;
	// public user: firebase.User;
	public loginForm: FormGroup;
	public loginFormSeller: FormGroup;

	public errorMessage: any;

	constructor(private fb: FormBuilder, public router: Router,private route: ActivatedRoute,private http: HttpClient,private location: Location,private uploadService: UploadService) {
	this.loginForm = this.fb.group({
	  username: ["", Validators.required],
	  pw: ["", Validators.required],
	});
	
	
	}
	
	

  ngOnInit() 
  {
	
		var url = window.location.hostname;
		var po_value=url.split('.');
		//alert(po_value[0]);
		//po_value[0]="namarathi";
		//this.test(po_value[0]);
		
  
		//this.username='Admin';
		//this.password='123456';
		
		
		this.sub = this.route
      .queryParams
      .subscribe(params => {
		  //alert(JSON.stringify(params['activation']));
        // Defaults to 0 if no query param provided.
        this.val = params['activation'];
		this.iv = params['a'];
		this.siv = params['b'];
		//alert(params['activation']);
		
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.options).subscribe((response) => 
			{
				this.token=response;
				//alert(this.token.access_token);
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
				if(this.val!==undefined && this.iv!==undefined)
				{				
					this.http.post(environment.api_url+'/v1/companyPO/activation',{ activation : this.val,iv : this.iv },o).subscribe((response) => 
					{		
								
								this.actMsg=true;
								this.registerMsg="Your Email is Verified. Now you can login your admin account";											
					});
				}
				else if(this.val!==undefined && this.siv!==undefined)
				{
					this.http.post(environment.api_url+'/v1/companySeller/activation',{ activation : this.val,iv : this.siv },o).subscribe((response) => 
					{									
								this.actMsg=true;
								this.registerMsg="Your Email is Verified. Now you can login your seller account";											
					});
				}
			});	
					
      });						
	  	 
  
  
  }

  
  
  
  login() {
  
	
	
    this.submitted = true;
        // stop here if form is invalid
	if (this.loginForm.invalid) 
	{		
		return;
	}	
	
	//alert(environment.api_url);
	this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.options).subscribe((response) => 
	{		
		this.token=response;
		//alert(this.token.access_token);
		let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
		let o = { headers: h };
					
		this.http.post(environment.api_url+'/signinSeller',this.loginForm.value,o).subscribe((response) => 
		{
			
				
				
				localStorage.setItem('userDetails', JSON.stringify(response[0]));
				
				localStorage.setItem('user_id', response[0].user_id);	
				localStorage.setItem('username', response[0].username);	
				localStorage.setItem('first_name', response[0].first_name);	
				localStorage.setItem('last_name', response[0].last_name);	
				
				this.router.navigate(['/master/Summary']);
				
				
			
		},(err: HttpErrorResponse) => {
		  if (err instanceof Error) {
			// client-side error
			//alert(JSON.stringify(err));
			//this.message = `An error occured ${err.error.message}`;
		  } 
		  else 
		  {					  					
			  this.isShown=true;
			this.msgSeller=err.error.message;
			return;
			 
			//this.message = `Backend returned error code ${err.status}, body was: ${err.message}`;
		  }
		});
	});	
	
	
	
	
	  
  }
  
  createAccount()
  {
	this.router.navigate(['/auth/Register']);	
  }

  loginFacebook(){

  }
  loginTwitter(){

  }
  loginGoogle(){

  }
  
 

}
