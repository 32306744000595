<div class="page-wrapper" >
<div class="authentication-main" >
<div class="container-fluid" >
  <div class="row">
    <div class="col-xl-12 xl-100">
      <div class="row">	  
	   <div class="text-center" style="padding:20px;"><img src="assets/images/logo.png"   /></div>		
        <div class="col-lg-12">
          <div class="card">           
        <div class="card-body">          		                                
			<form [formGroup]="registerForm" class="needs-validation" novalidate >
																
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5 >Basic Details</h5>
							</div>
							<div class="card-body">
								<div class="row">
									
									<div class="col-md-4 mb-3">
										<label >First Name *</label>
										<input class="form-control btn-square" type="text" formControlName="first_name" placeholder="" name="first_name" required>
										<div *ngIf="submitted && registerForm.controls['first_name'].errors">
											<div *ngIf="registerForm.controls['first_name'].errors?.['required']" class="text text-danger" >First Name is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Last Name</label>
										<input class="form-control btn-square" type="text" formControlName="last_name" placeholder="" name="last_name" >
									</div>									
									<div class="col-md-4 mb-3">
										<label >Phone *</label>
										<input class="form-control btn-square" type="number" formControlName="phone" placeholder="" name="phone"  required>
										<div *ngIf="submitted && registerForm.controls['phone'].errors"  >
											<div *ngIf="registerForm.controls['phone'].errors?.['required']" class="text text-danger" >Phone is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Username *</label>
										<input class="form-control btn-square" type="username" formControlName="username" placeholder="" name="username"  required (blur)="getUsername($event)" #user_name>
										<div *ngIf="submitted && registerForm.controls['username'].errors"  >
											<div *ngIf="registerForm.controls['username'].errors?.['required']" class="text text-danger" >Username is required</div>
										</div>
									</div>
									 <div class="col-md-4 mb-3">
										  <label >Password</label>
										  <input class="form-control btn-square" type="password" formControlName="pw" required />
											<div *ngIf="submitted && registerForm.controls['pw'].errors" >
												<div *ngIf="registerForm.controls['pw'].errors?.['required']" class="text text-danger" >Password is required</div>
											</div>
									</div>
									<div class="col-md-4 mb-3">
										  <label >Confirm Password</label>
										  <input class="form-control btn-square" type="password" formControlName="cpw" required (blur)="getPw($event)" #cpw/>
											<div *ngIf="submitted && registerForm.controls['cpw'].errors" >
												<div *ngIf="registerForm.controls['cpw'].errors?.['required']" class="text text-danger" >Confirm Password is required</div>
											</div>
									</div>
								</div>
							</div>
							<div class="card-footer" >
								<button class="btn btn-primary btn-sm" type="submit" placement="top" (click)="onSubmit();" >Register</button>
							</div>							 
						</div>								
						
					<!--	<a routerLink="/master/ViewVendor" class="btn btn-outline-light btn-pill btn-air-light btn-sm txt-dark" style="background-color:#FFFFFF">Cancel</a>-->
					<!--	<button class="btn btn-outline-light btn-pill btn-air-light btn-sm txt-dark" type="submit" placement="top" (click)="test();" style="background-color:#FFFFFF">Test</button>&nbsp;&nbsp;-->

						   
                    </form>
        </div>		
		</div>
		</div>						
		</div>
		</div>        
		</div>   
  
  
  
  
  
  
  </div>

  
</div>

</div>