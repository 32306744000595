<div class="page-wrapper">
  <div class="authentication-main">
    <div class="authentication-box">
      <div class="text-center" style="padding:20px;"><img src="assets/images/logo.png"  alt="80" /></div>
      <div class="card mt-4">
		<div class="card-header" style="padding:15px;background-color:#e5e5e5">                
			<h5>Change Password</h5> 
		</div>
        <div class="card-body">          		                                
          <form class="needs-validation" [formGroup]="loginForm" >
		  <div *ngIf="isShown" style="color:red;" >{{msg}}</div>
            <div class="form-group">
              <label class="col-form-label">New Password</label>
              <input class="form-control" type="password" formControlName="pw" required />
              <div *ngIf="submitted && loginForm.controls['pw'].errors"  >
				<div *ngIf="loginForm.controls['pw'].errors?.['required']" class="text text-danger" >Password is required</div>
			</div>
            </div>
			<div class="form-group">
              <label class="col-form-label">Confirm Password</label>
              <input class="form-control" type="password" formControlName="cpw" required (blur)="checkPassword($event)" #cpassword/>
              <div *ngIf="submitted && loginForm.controls['cpw'].errors"  >
				<div *ngIf="loginForm.controls['cpw'].errors?.['required']" class="text text-danger" >Confirm Password is required</div>
			  </div>
            </div>
            <div class="form-group row mt-3 mb-0">
			<!--[disabled]="!loginForm.valid"-->
              <button class="btn btn-primary btn-block"  (click)="login()" type="submit">
                <span>Change Password</span>
              </button>
            </div>
            <!--<div class="login-divider"></div>-->
            <!--<div class="social mt-3">
              <div class="form-group btn-showcase d-flex">
                 <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
                  <i class="fa fa-facebook"></i>
                </button>
                <button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
                  <i class="fa fa-twitter"></i>
                </button>
                <button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
                  <i class="fa fa-google"></i>
                </button> 
              </div>
            </div>-->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
