import { Component, QueryList, ViewChildren,OnInit,ViewChild,ElementRef,VERSION } from '@angular/core';
import { Observable } from 'rxjs';
import { Validators, FormBuilder, FormGroup, FormControl,FormArray } from '@angular/forms';
// import { count } from 'rxjs/operators';
// export interface Time {
import { HttpClient,HttpHeaders,HttpRequest, HttpEventType, HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from "@angular/common";
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgFor} from '@angular/common';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';
import { UploadService } from './upload.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

// }
@Component({
  selector: 'app-signupProcess',
  templateUrl: './signupProcess.component.html',
  styleUrls: ['./signupProcess.component.scss'],    
})
export class SignupProcessComponent{
  seconds: number;
  headers1 = new HttpHeaders({ 'Content-Type': 'application/json','API_KEY':'20e20m06i16e14r31p','Version':'1.0' });    	 
  opt = { headers: this.headers1 };
  head=new HttpHeaders({'Accept': 'application/json','Access-Control-Allow-Origin': '*','Content-Type': 'multipart/form-data'});
  option = { headers: this.head };
  token:any;
  vendor:any;
  qval:any;
  items: GalleryItem[];
  
  country:any;
  countryshort:any;

  imageData = data;
  
  
 
  
  
  movies = [
    'Episode I - The Phantom Menace',
    'Episode II - Attack of the Clones',
    'Episode III - Revenge of the Sith',
    'Episode IV - A New Hope',
    'Episode V - The Empire Strikes Back',
    'Episode VI - Return of the Jedi',
    'Episode VII - The Force Awakens',
    'Episode VIII - The Last Jedi',
    'Episode IX – The Rise of Skywalker',
  ];

  
  
  public config2: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 5,
    autoReset: null,
    errorReset: null,
    cancelReset: null
  };
  
	rows: FormArray;
	registerForm : FormGroup;	
	billingForm: FormGroup;
	cForm: FormGroup;
	aboutForm: FormGroup;
	tcForm: FormGroup;
	l1CategoryForm: FormGroup;
	l1MCategoryForm: FormGroup;
	l2CategoryForm: FormGroup;
	l2MCategoryForm: FormGroup;
	l3CategoryForm: FormGroup;
	l3MCategoryForm: FormGroup;
	bannerForm: FormGroup;
	MBannerForm: FormGroup;
	collectionForm: FormGroup;
	MCollectionForm: FormGroup;
	sellerForm: FormGroup;
	businessTypes: any;
	countries : any;
	countryProvinces: any;	
	submitted: boolean = false;
	next2Clicked: boolean = false;
	next3Clicked: boolean = false;
	next4Clicked: boolean = false;
	next5Clicked: boolean = false;
	next6Clicked: boolean = false;
	next7Clicked: boolean = false;
	next8Clicked: boolean = false;
	next9Clicked: boolean = false;
	next10Clicked: boolean = false;
	next11Clicked: boolean = false;
	next12Clicked: boolean = false;
	next13Clicked: boolean = false;
	next14Clicked: boolean = false;
	next15Clicked: boolean = false;	
	next16Clicked: boolean = false;	
	onL1Clicked: boolean = false;	
	onL2Clicked: boolean = false;	
	onL3Clicked: boolean = false;	
	onBannerClicked: boolean = false;
	onCollectionClicked: boolean = false;
	sellerSubmitted: boolean = false;
	minDobString:any;
	maxDobString:any;
	statelong:any;	
	
	//@ViewChild('logo_upload') logo_upload: ElementRef;
	
	rows1:any[];
	
	val:any;
	
	userDetails:any;
	selectedFiles: FileList;
	selectedFilesAbout: FileList;
	selectedBanner: FileList;

	imgResult: string = '';
	closeResult: string;
	
	prd_list:any;
	data1:any;
	
	//Form1
	company_po_id:any;
	cbusiness_type:any;
	cbusiness_vertical_name:any;
	cbusiness_vertical_description:any;
	cbusiness_name:any;
	cbusiness_subdomain:any;
	clegal_name:any;
	caddressline1:any;
	caddressline2:any;
	cstreet_no:any;
	cstreet_name:any;
	carea_name:any;
	cregion_name:any;
	czip:any;
	cstateshort:any;
	cadmin_first_name:any;
	cadmin_last_name:any;
	cadmin_gender:any;
	cadmin_dob:any;
	cadmin_title:any;
	cadmin_phone:any;
	cadmin_email:any;
	
	
	
	
	//Form2
	logo_upload:any;
	registration_number:any;
	registration_date:any;							
	pan_number:any;
	phone:any;
	office_email:any;
	accounts_email:any;
	accounts_phone:any;
	web_url:any;
	insta:any;	
	linkedin:any;
	password_renewal_days:any;			
	enquiry_email:any;
	monthly_purchase_minimum:any;
	maximum_products:any;
	billing_addressline1:any;
	billing_addressline2:any;
	billing_street_no:any;
	billing_street_name:any;
	billing_area:any;
	billing_region:any;
	billing_zip:any;
	billing_stateshort:any;
	gstin_number:any;
	public about_us_text='';
	about_us_image_url:any;
	terms_conditions:any;
	level1_name:any;
	level2_name:any;
	
	l1Categories:any=[];
	l2Categories:any=[];
	l3Categories:any=[];;
	banners:any=[];;
	collections:any=[];
	
	seller_id:any;
	seller_type:any;
	seller_business_type: any;				
	seller_business_name:any;					
	seller_legal_name: any;	
	seller_registration_number: any;
	seller_registration_date: any;
	seller_pan_number: any;
	seller_tan_number: any;
	seller_gstin_number: any;
	seller_comm_percentage: any;
	seller_maximum_products: any;
	seller_addressline1: any;
	seller_addressline2: any;			
	seller_street_no: any;
	seller_street_name: any;			
	seller_area_name: any;			
	seller_region_name: any;
	seller_zip: any;
	seller_stateshort: any;
	seller_phone: any;
	seller_office_email: any;
	seller_web_url: any;
	seller_insta: any;
	seller_linkedin: any;				
	seller_admin_first_name: any;
	seller_admin_last_name: any;
	seller_admin_gender: any;
	seller_admin_dob: any;
	seller_admin_title: any;
	seller_admin_phone: any;
	seller_admin_email: any;
	
	
	editorConfig: AngularEditorConfig = {
    editable: true,
	spellcheck: true,
      height: '400px',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],	
    };  

  public htmlContent = '';
	
	
  
  constructor(private http: HttpClient,private route: ActivatedRoute,private router: Router,private formBuilder: FormBuilder,config: NgbModalConfig, private modalService: NgbModal,public gallery: Gallery, public lightbox: Lightbox,private uploadService: UploadService) {    
	config.backdrop = 'static';
    config.keyboard = false;
	this.rows = this.formBuilder.array([]);
  }

  
	selectFile(event) 
	{
				
		if(event.target.files.item(0).type=='image/png' || event.target.files.item(0).type=='image/jpeg' || event.target.files.item(0).type=='image/webp')
		{
			this.selectedFiles = event.target.files;		
		}
		else
		{
			alert("Only allowed PNG or JPG or WEBP");
			this.cForm.controls['logo_upload'].setValue("");		
		}				
		
	}
	
	
	selectFileAbout(event) 
	{
				
		if(event.target.files.item(0).type=='image/png' || event.target.files.item(0).type=='image/jpeg' || event.target.files.item(0).type=='image/webp')
		{
			this.selectedFilesAbout = event.target.files;		
		}
		else
		{
			alert("Only allowed PNG or JPG or WEBP");
			this.aboutForm.controls['about_us_image_url'].setValue("");		
		}				
		
	}
	
	selectFileBanner(event) 
	{
		//alert(event.target.files.item(0));	
		if(event.target.files.item(0).type=='image/png' || event.target.files.item(0).type=='image/jpeg' || event.target.files.item(0).type=='image/webp')
		{
			this.selectedBanner = event.target.files;		
		}
		else
		{
			alert("Only allowed PNG or JPG or WEBP");
			this.bannerForm.controls['banner_url'].setValue("");		
		}
			
		
		
	}
  

  public ngOnInit() { 
  
		this.countryshort = environment.defaultCountryId;
		this.country = environment.defaultCountryId;
		
		this.password_renewal_days=90;			
		this.monthly_purchase_minimum=2000;		
		this.maximum_products=1000;
		
		var url = window.location.hostname;
		var po_value=url.split('.');
		var po_url=po_value[0]+".json";
		//var po_url="namarathi.json";
		

		let dat = new Date().toISOString();
		
		var yearMS = 365 * (1000 * 60 * 60 * 24); // 365 days
		var now = new Date().getTime();		
		var minDobMS = now - (122.5 * yearMS);		
		this.minDobString = new Date(minDobMS).toISOString().slice(0, 10);
		var eighteenYearsAgo = new Date();
		var eighteenYearsAgo1 = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear()-18);
		this.maxDobString =new Date(eighteenYearsAgo1).toISOString().slice(0, 10);					
		
		
		
		
		this.qval = this.route
		.queryParams
		.subscribe(params => {
		// Defaults to 0 if no query param provided.
		this.val = +params['val'] || 0;
		this.nextUrl(this.val-1);			
		
		});		
				
		
		
		//Form1 Fetch Details
		this.http.get('https://init-json.1234sys.com/'+po_url).subscribe((response) => 
		{
			
			this.company_po_id=response[0].id;
			this.cbusiness_type=response[0].business_type;
			this.cbusiness_vertical_name=response[0].business_vertical_name;
			this.cbusiness_vertical_description=response[0].business_vertical_description;
			this.cbusiness_name=response[0].business_name;
			this.cbusiness_subdomain=response[0].business_subdomain;
			this.clegal_name=response[0].legal_name;
			this.caddressline1=response[0].addressline1;
			this.caddressline2=response[0].addressline2;
			this.cstreet_no=response[0].street_no;
			this.cstreet_name=response[0].street_name;
			this.carea_name=response[0].area_name;
			this.cregion_name=response[0].region_name;
			this.czip=response[0].zip;
			this.cstateshort=response[0].stateshort;
		});
		
		var userDetails = JSON.parse(localStorage.getItem("userDetails") || '{}');				
		this.cadmin_first_name=userDetails.first_name;
		this.cadmin_last_name=userDetails.last_name;
		this.cadmin_gender=userDetails.gender;
		this.cadmin_dob=formatDate(userDetails.dob.toString(),'yyyy-MM-dd','en-US');
		this.cadmin_title=userDetails.title;
		this.cadmin_phone=userDetails.phone;				
		this.cadmin_email=userDetails.email_id;
		
		//Form2 Fetch Details
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
			
			this.http.get(environment.api_url+'/v1/companyPO/'+this.company_po_id,o).subscribe((response) => 
			{
				//alert(JSON.stringify(response));
				
				this.billingForm.controls['billing_name'].setValue(response[0].legal_name);
				if(response[0].signup_page2_over==true){
					
					this.logo_upload=response[0].logo_upload;					
					this.registration_number=response[0].registration_number;
					this.registration_date=formatDate(response[0].registration_date.toString(),'yyyy-MM-dd','en-US');
					this.pan_number=response[0].pan_number;
					this.phone=response[0].phone;	
					this.office_email=response[0].office_email;
					this.accounts_email=response[0].accounts_email;
					this.accounts_phone=response[0].accounts_phone;
					this.web_url=response[0].web_url;
					this.insta=response[0].insta;
					this.linkedin=response[0].linkedin;
					this.password_renewal_days=response[0].password_renewal_days;	
					this.enquiry_email=response[0].enquiry_email;
					this.monthly_purchase_minimum=response[0].monthly_purchase_minimum;		
					this.maximum_products=response[0].maximum_products;			
				}
				
				if(response[0].signup_page3_over==true){
					this.billing_addressline1=response[0].billing_addressline1;		
					this.billing_addressline2=response[0].billing_addressline2;		
					this.billing_street_no=response[0].billing_street_no;		
					this.billing_street_name=response[0].billing_street_name;		
					this.billing_area=response[0].billing_area;		
					this.billing_region=response[0].billing_region;		
					this.billing_zip=response[0].billing_zip;		
					this.billing_stateshort=response[0].billing_stateshort;		
					this.gstin_number=response[0].gstin_number;		
				}
				
				if(response[0].signup_page4_over==true){									
					this.about_us_text=response[0].about_us_text;		
					this.about_us_image_url=response[0].about_us_image_url;								
				}
				
				if(response[0].signup_page5_over==true){
					this.terms_conditions=response[0].terms_conditions;													
				}
				
				//if(response[0].signup_page6_over==true){
					
					
						
				//}
						
			});		
			
				this.http.get(environment.api_url+'/v1/l1CategoryPO/company_po_id/'+this.company_po_id,o).subscribe((response) => 
				{													
					this.l1Categories=response;
					setTimeout(()=>{ 
					$('#l1').DataTable( {
						  pagingType: 'full_numbers',
						  pageLength: 5,
						  processing: true,
						  lengthMenu : [5, 10, 25],
						  dom: '<"pull-left"f><"pull-right"l>tip',						 
						} );	
					}, 1);					
				});	
				
				this.http.get(environment.api_url+'/v1/l2CategoryPO/company_po_id/'+this.company_po_id,o).subscribe((response) => 
				{						
					this.l2Categories=response;	
					setTimeout(()=>{ 
					$('#l2').DataTable( {
					  pagingType: 'full_numbers',
					  pageLength: 5,
					  processing: true,
					  lengthMenu : [5, 10, 25],
					  dom: '<"pull-left"f><"pull-right"l>tip',
					} );																
					}, 1);	
				});
				
				this.http.get(environment.api_url+'/v1/l3CategoryPO/company_po_id/'+this.company_po_id,o).subscribe((response) => 
				{						
					this.l3Categories=response;		
					setTimeout(()=>{ 
					$('#l3').DataTable( {
					  pagingType: 'full_numbers',
					  pageLength: 5,
					  processing: true,
					  lengthMenu : [5, 10, 25],
					  dom: '<"pull-left"f><"pull-right"l>tip',
					} );																
					}, 1);
				});
				
				this.http.get(environment.api_url+'/v1/companyBanner/company_po_id/'+this.company_po_id,o).subscribe((response) => 
				{						
					this.banners=response;
					setTimeout(()=>{ 
					$('#banner').DataTable( {
					  pagingType: 'full_numbers',
					  pageLength: 5,
					  processing: true,
					  lengthMenu : [5, 10, 25],
					  dom: '<"pull-left"f><"pull-right"l>tip',
					} );	
					}, 1);
				});
				
				
				this.http.get(environment.api_url+'/v1/companyCollection',o).subscribe((response) => 
				{						
					this.collections=response;	
					setTimeout(()=>{ 
					$('#collection').DataTable( {
					  pagingType: 'full_numbers',
					  pageLength: 5,
					  processing: true,
					  lengthMenu : [5, 10, 25],
					  dom: '<"pull-left"f><"pull-right"l>tip',
					} );	
					}, 1);
				});
				
				this.http.get(environment.api_url+'/v1/companySeller/BusinessName/'+this.cbusiness_name,o).subscribe((response) => 
				{
						//alert(JSON.stringify(response));
					this.seller_id=response[0].seller_id;
					this.seller_type=response[0].seller_type;
					this.seller_business_type=response[0].business_type;
					this.seller_business_name=response[0].business_name;					
					this.seller_legal_name=response[0].legal_name;	
					this.seller_registration_number=response[0].registration_number;
					if(response[0].registration_date!=undefined)
					{
					this.seller_registration_date=formatDate(response[0].registration_date.toString(),'yyyy-MM-dd','en-US');
					}
					this.seller_pan_number=response[0].pan_number;
					this.seller_tan_number=response[0].tan_number;
					this.seller_gstin_number=response[0].gstin_number;
					this.seller_comm_percentage=response[0].comm_percentage;
					this.seller_maximum_products=response[0].maximum_products;
					this.seller_addressline1=response[0].addressline1;
					this.seller_addressline2=response[0].addressline2;			
					this.seller_street_no=response[0].street_no;
					this.seller_street_name=response[0].street_name;		
					this.seller_area_name=response[0].area_name;		
					this.seller_region_name=response[0].region_name;
					this.seller_zip=response[0].zip;
					this.seller_stateshort=response[0].stateshort;
					this.seller_phone=response[0].phone;
					this.seller_office_email=response[0].office_email;
					this.seller_web_url=response[0].web_url;
					this.seller_insta=response[0].insta;
					this.seller_linkedin=response[0].linkedin;				
					this.seller_admin_first_name=response[0].admin_first_name;
					this.seller_admin_last_name=response[0].admin_last_name;
					this.seller_admin_gender=response[0].admin_gender;						
					this.seller_admin_dob=formatDate(response[0].admin_dob.toString(),'yyyy-MM-dd','en-US');
					this.seller_admin_title=response[0].admin_title;
					this.seller_admin_phone=response[0].admin_phone;
					this.seller_admin_email=response[0].admin_email;
				});
		});	
		
		
		
		
		
		//alert(this.business_name);
		//var d1 = JSON.parse(d || '{}');
		
		
		//this.data1=JSON.parse(data);
		
		//alert(JSON.stringify(this.data1));
		
		
		
		
		this.cForm = this.formBuilder.group({            
			logo_upload: [''],
			registration_number: [''],
			registration_date: [''],							
			pan_number: [''],
			phone: ['', Validators.required],			
			office_email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
			accounts_email: [''],			
			accounts_phone: [''],
			web_url: [''],	
			insta: [''],	
			linkedin: [''],	
			password_renewal_days: [''],			
			enquiry_email: [''],
			monthly_purchase_minimum: [''],
			maximum_products: [''],									
			
			//email: ['', [Validators.required, Validators.email]],
           // password: ['', [Validators.required, Validators.minLength(6)]]
        });
		
		this.billingForm = this.formBuilder.group({            
			billing_name: [''],
			gstin_number: [''],
			billing_addressline1: [''],
			billing_addressline2: [''],			
			billing_street_no: [''],
			billing_street_name: [''],			
			billing_area:  ['', Validators.required],			
			billing_region: [''],			
			billing_zip: ['', Validators.required],		
			billing_stateshort: ['', Validators.required],	
					
			
			//email: ['', [Validators.required, Validators.email]],
           // password: ['', [Validators.required, Validators.minLength(6)]]
        });
		
		this.aboutForm = this.formBuilder.group({            
			about_us_text: ['',Validators.required],
			about_us_image_url: [''],											
        });
		//	alert(this.legal_name);
		
		this.tcForm = this.formBuilder.group({            
			terms_conditions: ['',Validators.required],										
        });
		
		this.l1CategoryForm = this.formBuilder.group({            
			level1_name: ['',Validators.required],	
			description: ['',Validators.required],
			level1_order_id: ['',Validators.required],
        });
		
		this.l1MCategoryForm = this.formBuilder.group({            
			level1_name: ['',Validators.required],	
			description: ['',Validators.required],
			level1_order_id: ['',Validators.required],
        });
		
		this.l2CategoryForm = this.formBuilder.group({            
			level1_id: ['',Validators.required],	
			level2_name: ['',Validators.required],				
			level2_order_id: ['',Validators.required],
        });
		
		this.l2MCategoryForm = this.formBuilder.group({            
			level1_id: ['',Validators.required],	
			level2_name: ['',Validators.required],				
			level2_order_id: ['',Validators.required],
        });
		
		this.l3CategoryForm = this.formBuilder.group({            
			level1_id: ['',Validators.required],	
			level2_id: ['',Validators.required],	
			level3_name: ['',Validators.required],				
			level3_order_id: ['',Validators.required],
        });
		
		this.l3MCategoryForm = this.formBuilder.group({            
			level1_id: ['',Validators.required],	
			level2_id: ['',Validators.required],	
			level3_name: ['',Validators.required],				
			level3_order_id: ['',Validators.required],
        });
		
		this.bannerForm = this.formBuilder.group({            
			category1_id: ['',Validators.required],	
			category2_id: [''],	
			category3_id: [''],	
			order_id: ['',Validators.required],
			banner_url:['',Validators.required],	
			banner_slogan:['',Validators.required],
			banner_button_text:['',Validators.required],
			
        });
		
		this.MBannerForm = this.formBuilder.group({            
			category1_id: ['',Validators.required],	
			category2_id: [''],	
			category3_id: [''],	
			order_id: ['',Validators.required],
			banner_url:['',Validators.required],	
			banner_slogan:['',Validators.required],
			banner_button_text:['',Validators.required],
			
        });
		
		this.collectionForm = this.formBuilder.group({            
			collection_name: ['',Validators.required],
			collection_enable_select: [''],
			collection_enable_display: [''],			
        });
		
		this.MCollectionForm = this.formBuilder.group({            
			collection_name: ['',Validators.required],
			collection_enable_select: [''],
			collection_enable_display: [''],
        });
		
		
		
		this.sellerForm = this.formBuilder.group({            
			seller_type: ['', Validators.required],
			seller_business_type: ['', Validators.required],				
			seller_business_name: ['', Validators.required],					
			seller_legal_name: ['', Validators.required],	
			seller_registration_number: [''],	
			seller_registration_date: [''],
			seller_pan_number: ['', Validators.required],
			seller_tan_number: [''],
			seller_gstin_number: ['', Validators.required],
			seller_comm_percentage: ['', Validators.required],
			seller_maximum_products: ['', Validators.required],
			seller_addressline1: [''],
			seller_addressline2: [''],			
			seller_street_no: [''],
			seller_street_name: [''],			
			seller_area_name: [''],			
			seller_region_name: [''],			
			seller_zip: ['', Validators.required],		
			seller_stateshort: ['', Validators.required],
			seller_phone: ['', Validators.required],			
			seller_office_email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
			seller_web_url: [''],	
			seller_insta: [''],	
			seller_linkedin: [''],				
			seller_admin_first_name: ['', Validators.required],
			seller_admin_last_name: [''],
			seller_admin_gender: ['', Validators.required],
			seller_admin_dob: ['', Validators.required],
			seller_admin_title: ['', Validators.required],
			seller_admin_phone: ['', Validators.required],				
			seller_admin_email: ['', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
			//email: ['', [Validators.required, Validators.email]],
            //password: ['', [Validators.required, Validators.minLength(6)]]
        });
		
	//	alert(this.val);
		
		
  
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			//alert(this.token.access_token);
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
			
			this.http.get(environment.api_url+'/v1/country',o).subscribe((response) => 
			{ 	
				this.countries=response;			
						
			});
			
			this.http.get(environment.api_url+'/v1/countryProvince/Country/'+environment.defaultCountryId,o).subscribe((response) => 
			{ 					
				this.countryProvinces=response;			
						
			});
			
								
						
		});	
		
		this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

		/** Lightbox Example */

		// Get a lightbox gallery ref
		const lightboxRef = this.gallery.ref('lightbox');

		// Add custom gallery config to the lightbox (optional)
		lightboxRef.setConfig({
		  imageSize: ImageSize.Cover,
		  thumbPosition: ThumbnailsPosition.Top
		});

		// Load items into the lightbox gallery ref
		lightboxRef.load(this.items);
		
		
		
		
  }
  
		
  
  
		get addDynamicRow() 
		{
			return this.registerForm.get('rows') as FormArray;
		}

		onAddRow() 
		{
			this.rows.push(this.createItemFormGroup());
			this.rows1=JSON.parse(JSON.stringify(this.rows.value));
			//console.log(JSON.parse(JSON.stringify(this.rows.value)));
		}

		onRemoveRow(rowIndex:number)
		{			
			this.rows.removeAt(rowIndex);	
			this.rows1=JSON.parse(JSON.stringify(this.rows.value));
		}

		createItemFormGroup(): FormGroup {
			return this.formBuilder.group({
				title: [null],
				description: [null]		
			});
		}
		
		drop(event: CdkDragDrop<string[]>) 
		{			
			moveItemInArray(this.rows1, event.previousIndex, event.currentIndex);
		}
		
		openLg(content) {
			this.modalService.open(content, { size: 'lg' });
		  }
  
		openVerticallyCentered(content) 
		{
			this.rows1=JSON.parse(JSON.stringify(this.rows.value));
			this.modalService.open(content, { centered: true });
		}
		
		/*getl1Category(tr)
		{		
		  this.level1_name=tr.target.options[tr.target.options.selectedIndex].text;	  	 		 
		}
		
		getl2Category(tr)
		{		
		  this.level2_name=tr.target.options[tr.target.options.selectedIndex].text;	  	 		 
		}*/
		
		
  
  
		public onUploadInit(args: any): void {  }

		public onUploadError(args: any): void {  }

		public onUploadSuccess(args: any): void {  }   
  
  
    getState(tr)
	{		
	  this.statelong=tr.target.options[tr.target.options.selectedIndex].text;	  	 
	 
	}
		
				
	
	
	async next(n)
	{
		 var step1 = window.document.getElementById("1")!;
		 var step2 = window.document.getElementById("2")!;
		 var step3 = window.document.getElementById("3")!;
		 var step4 = window.document.getElementById("4")!;
		 var step5 = window.document.getElementById("5")!;
		 var step6 = window.document.getElementById("6")!;
		 var step7 = window.document.getElementById("7")!;
		 var step8 = window.document.getElementById("8")!;
		 var step9 = window.document.getElementById("9")!;
		 var step10 = window.document.getElementById("10")!;
		 var step11 = window.document.getElementById("11")!;
		 var step12 = window.document.getElementById("12")!;
		 var step13 = window.document.getElementById("13")!;
		 var step14 = window.document.getElementById("14")!;
		 var step15 = window.document.getElementById("15")!;
		 var step16 = window.document.getElementById("16")!;
		 if(n==1)
		 {		 
			const element1 = window.document.getElementById("form1")!;
			const element2 = window.document.getElementById("form2")!;			
			element1.style.display="none";
			element2.style.display="block";
			step1.className="u-pearl done col-1";
			step2.className="u-pearl current col-1";
			//this.seller.nativeElement.focus();
		 }
		 else if(n==2)
		 {	
		 
			this.next2Clicked = true;
			
			if(this.logo_upload!='')
			{
				
				this.cForm.value.logo_upload=this.logo_upload;
			}
			else
			{
				if(this.cForm.value.logo_upload=='')
				{
					alert("Please Upload Logo");
					return;
				}
			}
			
			
			if (this.cForm.invalid) 
			{							
				return;
			}
			
			if(this.logo_upload=='' || this.logo_upload==undefined)
			{	
				//alert(this.logo_upload);
				await this.uploadService.upload(this.selectedFiles.item(0),this.company_po_id).then((res)=>
				{ 					
					//alert(JSON.stringify(res));
					this.cForm.value.logo_upload=res;
				});
			}
			//alert(this.cForm.value.logo_upload)
			
						
			this.cForm.value.id=this.company_po_id;
			
		//	alert(JSON.stringify(this.cForm.value))
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.post(environment.api_url+'/v1/companyPO/page2',this.cForm.value,o).subscribe((response) => 
				{									
					alert("Company Details Added Successfully")
					this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 3 } })		
						
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
				  
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	
									
		 }
		 else if(n==3)
		 {			
			this.next3Clicked = true;
			if (this.billingForm.invalid) 
			{
				return;
			}	

			delete this.billingForm.value.billing_name;
			
					
			this.billingForm.value.billing_countryshort=this.country;	
			this.billingForm.value.billing_country=this.country;			
			this.billingForm.value.statelong=this.statelong;
			
			this.billingForm.value.id=this.company_po_id;
			
			//alert(JSON.stringify(this.billingForm.value))
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.post(environment.api_url+'/v1/companyPO/page3',this.billingForm.value,o).subscribe((response) => 
				{									
					alert("Billing Details Added Successfully")
					this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 4 } })		
						
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
				  
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	
						
		 }
		 else if(n==4)
		 {	

			this.next4Clicked = true;
			if (this.aboutForm.invalid) 
			{
				return;
			}
			
			if(this.aboutForm.value.about_us_image_url!='')
			{
				await this.uploadService.uploadAbout(this.selectedFilesAbout.item(0),this.company_po_id).then((res)=>
				{ 					
					this.aboutForm.value.about_us_image_url=res;
				});
			}
			//alert(this.cForm.value.logo_upload)
			
						
			this.aboutForm.value.id=this.company_po_id;
			
			//alert(JSON.stringify(this.aboutForm.value))
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.post(environment.api_url+'/v1/companyPO/page4',this.aboutForm.value,o).subscribe((response) => 
				{									
					alert("About Us Added Successfully")
					this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 5 } })		
						
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
				  
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	
			
		 }
		 else if(n==5)
		 {		

			this.next5Clicked = true;
			if (this.tcForm.invalid) 
			{
				return;
			}
											
			this.tcForm.value.id=this.company_po_id;
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.post(environment.api_url+'/v1/companyPO/page5',this.tcForm.value,o).subscribe((response) => 
				{									
						alert("Terms and Conditions Added Successfully")
						this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 6 } })
						
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
				  
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	
			
		 }		 
		 else if(n==6)
		 {
			if(this.l1Categories.length==0 || this.l1Categories.length==undefined)
			{
				alert("Please Enter Atleast One Level1 Category")
				return;
			}
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 7 } })	
		 }
		 else if(n==7)
		 {										
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 8 } })	
		 }
		 else if(n==8)
		 {		 
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 9 } })	
		 }
		 else if(n==9)
		 {						
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 10 } })	
		 }
		 else if(n==10)
		 {						
			//alert(n)
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 11 } })	
		 }		 
		 
	}
	
	back(n)
	{
		 if(n==1)
		 {			
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 1 } })	
		 }
		 if(n==2)
		 {			
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 2 } })	
		 }
		 else if(n==3)
		 {										
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 3 } })	
		 }
		 else if(n==4)
		 {		 
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 4 } })	
		 }
		 else if(n==5)
		 {						
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 5 } })	
		 }
		 else if(n==6)
		 {						
			//alert(n)
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 6 } })	
		 }
		 else if(n==7)
		 {						
			//alert(n)
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 7 } })	
		 }
		 else if(n==8)
		 {						
			//alert(n)
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 8 } })	
		 }
		 else if(n==9)
		 {						
			//alert(n)
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 9 } })	
		 }
		 else if(n==10)
		 {						
			//alert(n)
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 10 } })	
		 }
		 
	}
	
	nextUrl(n)
	{
		 var step1 = window.document.getElementById("1")!;
		 var step2 = window.document.getElementById("2")!;
		 var step3 = window.document.getElementById("3")!;
		 var step4 = window.document.getElementById("4")!;
		 var step5 = window.document.getElementById("5")!;
		 var step6 = window.document.getElementById("6")!;
		 var step7 = window.document.getElementById("7")!;
		 var step8 = window.document.getElementById("8")!;
		 var step9 = window.document.getElementById("9")!;
		 var step10 = window.document.getElementById("10")!;
		 var step11 = window.document.getElementById("11")!;
	//	 var step12 = window.document.getElementById("12")!;		 
		 var element1 = window.document.getElementById("form1")!;
		var element2 = window.document.getElementById("form2")!;
		var element3 = window.document.getElementById("form3")!;
		var element4 = window.document.getElementById("form4")!;
		var element5 = window.document.getElementById("form5")!;
		var element6 = window.document.getElementById("form6")!;
		var element7 = window.document.getElementById("form7")!;
		var element8 = window.document.getElementById("form8")!;
		var element9 = window.document.getElementById("form9")!;
		var element10 = window.document.getElementById("form10")!;
		var element11 = window.document.getElementById("form11")!;
		//var element12 = window.document.getElementById("form12")!;
		/*var element13 = window.document.getElementById("form13")!;
		var element14 = window.document.getElementById("form14")!;
		var element15 = window.document.getElementById("form15")!;
		var element16 = window.document.getElementById("form16")!;*/
		 if(n==1)
		 {		 										
			element1.style.display="none";
			element2.style.display="block";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
		//	element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl current col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
		//	step12.className="u-pearl done col-1";
			
			//this.seller.nativeElement.focus();
		 }
		 else if(n==2)
		 {		 								
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="block";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl current col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		 else if(n==3)
		 {	
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="block";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl current col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		 else if(n==4)
		 {		 
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="block";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl current col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		 else if(n==5)
		 {		 			
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="block";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl current col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		 else if(n==6)
		 {		 
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="block";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl current col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		 else if(n==7)
		 {		 
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="block";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl current col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";			
		 }
		 else if(n==8)
		 {
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="block";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl current col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";

		 }
		 else if(n==9)
		 {		
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="block";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl current col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";			
		 }
		 else if(n==10)
		 {	
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="block";
			//element12.style.display="none";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl current col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		 else if(n==11)
		 {	
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="block";
			/*element13.style.display="none";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl current col-1";
			
		 }
		 else if(n==12)
		 {	
			element1.style.display="none";
			element2.style.display="none";
			element3.style.display="none";
			element4.style.display="none";
			element5.style.display="none";
			element6.style.display="none";
			element7.style.display="none";
			element8.style.display="none";
			element9.style.display="none";
			element10.style.display="none";
			element11.style.display="none";
			//element12.style.display="none";
			/*element13.style.display="block";
			element14.style.display="none";
			element15.style.display="none";
			element16.style.display="none";*/
			step1.className="u-pearl done col-1";
			step2.className="u-pearl done col-1";
			step3.className="u-pearl done col-1";
			step4.className="u-pearl done col-1";
			step5.className="u-pearl done col-1";
			step6.className="u-pearl done col-1";
			step7.className="u-pearl done col-1";
			step8.className="u-pearl done col-1";
			step9.className="u-pearl done col-1";
			step10.className="u-pearl done col-1";
			step11.className="u-pearl done col-1";
			//step12.className="u-pearl done col-1";
			
		 }
		
		
		 
	}
	
	onL1Model()
	{
		this.onL1Clicked = true;
		if (this.l1MCategoryForm.invalid) 
		{
			return;
		}	
		
		this.l1MCategoryForm.value.company_po_id=this.company_po_id;
		
	//	alert(JSON.stringify(this.l1MCategoryForm.value))
		
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
		
			this.http.post(environment.api_url+'/v1/l1CategoryPO',this.l1MCategoryForm.value,o).subscribe((response) => 
			{			
						this.http.post(environment.api_url+'/v1/companyPO/page6',{company_po_id:this.company_po_id},o).subscribe((response) => 
						{									
																	
						});	
						
						this.http.get(environment.api_url+'/v1/l1CategoryPO/company_po_id/'+this.company_po_id,o).subscribe((response) => 
						{													
							this.l1Categories=response;										
								
						});	
						alert("Level1 Category Added Successfully")			
						this.modalService.dismissAll();							
						window.location.reload();											
					
			},(err: HttpErrorResponse) => {
			  if (err instanceof Error) 
			  {
			  
				alert(err.error.message);					
				//this.message = `An error occured ${err.error.message}`;
			  } 
			  else 
			  {					  										  
				alert(err.error.message);
				return;
			  }
			});
														
		});	
	}
	
	onL2Model()
	{
		this.onL2Clicked = true;
		if (this.l2MCategoryForm.invalid) 
		{
			return;
		}			
		this.l2MCategoryForm.value.level1_name=this.level1_name
	//	alert(JSON.stringify(this.l1MCategoryForm.value))
		
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
		
			this.http.post(environment.api_url+'/v1/l2CategoryPO',this.l2MCategoryForm.value,o).subscribe((response) => 
			{																							
						this.http.post(environment.api_url+'/v1/companyPO/page8',{company_po_id:this.company_po_id},o).subscribe((response) => 
						{	
																							
						});	
						this.http.get(environment.api_url+'/v1/l2CategoryPO/company_po_id/'+this.company_po_id,o).subscribe((response) => 
						{													
							this.l2Categories=response;
							// here I am rendering web api data								
							
						});	
						alert("Level2 Category Added Successfully")
						this.modalService.dismissAll();	
						window.location.reload();													
					
			},(err: HttpErrorResponse) => {
			  if (err instanceof Error) 
			  {
			  
				alert(err.error.message);					
				//this.message = `An error occured ${err.error.message}`;
			  } 
			  else 
			  {					  										  
				alert(err.error.message);
				return;
			  }
			});
														
		});	
	}
	
	onL3Model()
	{
		this.onL3Clicked = true;
		if (this.l3MCategoryForm.invalid) 
		{
			return;
		}			
		this.l3MCategoryForm.value.level1_name=this.level1_name
		this.l3MCategoryForm.value.level2_name=this.level2_name
	//	alert(JSON.stringify(this.l1MCategoryForm.value))
		
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
		
			this.http.post(environment.api_url+'/v1/l3CategoryPO',this.l3MCategoryForm.value,o).subscribe((response) => 
			{																							
						this.http.post(environment.api_url+'/v1/companyPO/page9',{company_po_id:this.company_po_id},o).subscribe((response) => 
						{		
															
						});	
						this.http.get(environment.api_url+'/v1/l3CategoryPO/company_po_id/'+this.company_po_id,o).subscribe((response) => 
						{
							
							this.l3Categories=response;
							// here I am rendering web api data																	
						});
						alert("Level3 Category Added Successfully")
						this.modalService.dismissAll();		
						window.location.reload();		
																				
					
			},(err: HttpErrorResponse) => {
			  if (err instanceof Error) 
			  {
			  
				alert(err.error.message);					
				//this.message = `An error occured ${err.error.message}`;
			  } 
			  else 
			  {					  										  
				alert(err.error.message);
				return;
			  }
			});
														
		});	
	}
	
	
	async onBannerModel()
	{
		this.onBannerClicked = true;
		if (this.MBannerForm.invalid) 
		{
			return;
		}	

		if(this.MBannerForm.value.category2_id=='')
		{
			this.MBannerForm.value.category2_id=null;
		}
		
		if(this.MBannerForm.value.category3_id=='')
		{
			this.MBannerForm.value.category3_id=null;
		}
	
					
		await this.uploadService.uploadBanner(this.selectedBanner.item(0),this.company_po_id).then((res)=>
		{ 					
			this.MBannerForm.value.banner_url=res;
		});
			
			
						
			this.MBannerForm.value.company_po_id=this.company_po_id;		

			//alert(JSON.stringify(this.bannerForm.value));
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.post(environment.api_url+'/v1/companyBanner',this.MBannerForm.value,o).subscribe((response) => 
				{															
						this.http.post(environment.api_url+'/v1/companyPO/page10',{company_po_id:this.company_po_id},o).subscribe((response) => 
						{		
										
						});	
						this.http.get(environment.api_url+'/v1/companyBanner/company_po_id/'+this.company_po_id,o).subscribe((response) => 
						{						
							this.banners=response;								   								
						});
						alert("Banner Added Successfully");
						this.modalService.dismissAll();		
						window.location.reload();		
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
				  
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	
		
	}
	
	onCollectionModel()
	{
		this.onCollectionClicked = true;
		if (this.MCollectionForm.invalid) 
			{
				return;
			}

			if(this.MCollectionForm.value.collection_enable_select=='')
			{
				this.MCollectionForm.value.collection_enable_select=false;
			}
			
			if(this.MCollectionForm.value.collection_enable_display=='')
			{
				this.MCollectionForm.value.collection_enable_display=false;
			}
						
			this.MCollectionForm.value.company_po_id=this.company_po_id;		
		
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.post(environment.api_url+'/v1/companyCollection',this.MCollectionForm.value,o).subscribe((response) => 
				{															
						this.http.post(environment.api_url+'/v1/companyPO/page11',{company_po_id:this.company_po_id},o).subscribe((response) => 
						{			
																	
						});	
						this.http.get(environment.api_url+'/v1/companyCollection',o).subscribe((response) => 
						{						
							this.collections=response;	
						});
						alert("Collection Added Successfully");												
						this.modalService.dismissAll();
						window.location.reload();	
						
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
					
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	

	}
	
	onSeller()
	{
		
			this.sellerSubmitted = true;
			if (this.sellerForm.invalid) 
			{
				return;
			}
			
			this.sellerForm.value.seller_countryshort=this.country;	
			this.sellerForm.value.seller_country=this.country;			
			this.sellerForm.value.seller_statelong=this.statelong;
		
			this.sellerForm.value.company_id=this.company_po_id;
			this.sellerForm.value.seller_id=this.seller_id;	
			this.sellerForm.value.business_subdomain=this.cbusiness_subdomain;				
			
		//	alert(JSON.stringify(this.sellerForm.value));
			
			this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
			{
				this.token=response;
				let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
				let o = { headers: h };
			
				this.http.put(environment.api_url+'/v1/companySeller/'+this.seller_id,this.sellerForm.value,o).subscribe((response) => 
				{															
						this.http.post(environment.api_url+'/v1/companyPO/page12',{company_po_id:this.company_po_id},o).subscribe((response) => 
						{									
								this.router.navigate(['/dashboard/demo']);									
						});	
						
				},(err: HttpErrorResponse) => {
				  if (err instanceof Error) 
				  {
				  
					alert(err.error.message);					
					//this.message = `An error occured ${err.error.message}`;
				  } 
				  else 
				  {					  										  
					alert(err.error.message);
					return;
				  }
				});
															
			});	

	}
	
	
	

}

const data = [
  {
    srcUrl: 'assets/images/lightgallry/01.jpg',
    previewUrl: 'assets/images/lightgallry/01.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/02.jpg',
    previewUrl: 'assets/images/lightgallry/02.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/03.jpg',
    previewUrl: 'assets/images/lightgallry/03.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/04.jpg',
    previewUrl: 'assets/images/lightgallry/04.jpg'
  }/*,
  {
    srcUrl: 'assets/images/lightgallry/05.jpg',
    previewUrl: 'assets/images/lightgallry/05.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/06.jpg',
    previewUrl: 'assets/images/lightgallry/06.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/07.jpg',
    previewUrl: 'assets/images/lightgallry/07.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/08.jpg',
    previewUrl: 'assets/images/lightgallry/08.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/09.jpg',
    previewUrl: 'assets/images/lightgallry/09.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/010.jpg',
    previewUrl: 'assets/images/lightgallry/010.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/011.jpg',
    previewUrl: 'assets/images/lightgallry/011.jpg'
  },
  {
    srcUrl: 'assets/images/lightgallry/012.jpg',
    previewUrl: 'assets/images/lightgallry/012.jpg'
  }*/
]
