import { Component, OnInit,ElementRef,ViewChild } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient ,HttpHeaders,HttpErrorResponse  } from '@angular/common/http';
import { formatDate } from "@angular/common";
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
  selector: "app-changePassword",
  templateUrl: "./changePassword.component.html",
  styleUrls: ["./changePassword.component.scss"],
})
export class ChangePasswordComponent implements OnInit {

	val:any;
	sub:any;
	customers:any;
	isShown: boolean = false ;	
	submitted: boolean = false;
	submittedSeller: boolean = false;
	cmpisShown: boolean = false ;	
	cmpsubmitted: boolean = false;	
	actMsg: boolean=false;
	poMessage: boolean;	
	poMessage1: boolean;	
	msg:any;
	cmpmsg:any;
	dst_date_from:any;
	dst_date_to:any;
	headers = new HttpHeaders({ 'Content-Type': 'application/json','API_KEY':'20e20m06i16e14r31p','Version':'1.0' });    	 
	options = { headers: this.headers };	
	username:any;
	password:any;
	token:any;
	opt:any;
	cpw:any;
	iv:any;
	registerMsg:any;
	userDetails:any;
	user_po_id:any;
	
	public newUser = false;
	// public user: firebase.User;
	public loginForm: FormGroup;	

	public errorMessage: any;
	@ViewChild("business_name") business_name: ElementRef;
	

	constructor(public fb: FormBuilder, public router: Router,private route: ActivatedRoute,private http: HttpClient,private location: Location) 
	{
			
	}
	

  ngOnInit() 
  {		
		
		this.loginForm = this.fb.group({	  
		  pw: ["", Validators.required],
		  cpw: ["", Validators.required],
		});
	  	 
		this.user_po_id=localStorage.getItem('user_po_id');
		
  }
  
  checkPassword(tr)
  {
	
	if(this.loginForm.controls['pw'].value!=tr.target.value)
	{
		alert("Password Not Match");
		this.loginForm.controls['cpw'].setValue("");
	}	
  }

  login() 
  {
    
    this.submitted = true;        
	if (this.loginForm.invalid) 
	{		
		return;
	}		
	
	this.loginForm.value.pw_changed_date=new Date().toISOString();
	this.loginForm.value.user_po_id=this.user_po_id;
	this.loginForm.value.pw_changed=true;
	
	this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.options).subscribe((response) => 
	{
		this.token=response;
		//alert(this.token.access_token);
		let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
		let o = { headers: h };
		
		
						
		this.http.post(environment.api_url+'/v1/companyPO/changepassword',this.loginForm.value,o).subscribe((response) => 
		{
									
			this.router.navigate(['/auth/SignupProcess'],{ queryParams: { val: 2 } });
			
		},(err: HttpErrorResponse) => {
		  if (err instanceof Error) {
			// client-side error
			//alert(JSON.stringify(err));
			//this.message = `An error occured ${err.error.message}`;
		  } 
		  else 
		  {					  					
			  this.isShown=true;
			this.msg=err.error.message;
			return;
			 
			//this.message = `Backend returned error code ${err.status}, body was: ${err.message}`;
		  }
		});
	});	
	
	
	
	
	  
  }

  
 

}
