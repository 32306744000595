import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import {
  S3Client,
  ListBucketsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
  SelectObjectContentCommand
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public FOLDER="Uploads";
  public filename;

  constructor() { }
  

async checkR2(po_value)
{

	const S3 = new S3Client({
	  region: "auto",	    
		endpoint: environment.R2endpoint,
		credentials: {		
		accessKeyId: environment.R2accessKeyId,		
		secretAccessKey: environment.R2secretAccessKey,
	    },
	});	
	
	var file=po_value+".json";
			
	const ret= await getSignedUrl(S3, new GetObjectCommand({Bucket: 'init-json', Key: file}), { expiresIn: 3600 });			
	
	return ret;
}

	


}

