import { Component, OnInit } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { HttpClient ,HttpHeaders,HttpErrorResponse  } from '@angular/common/http';
import { formatDate } from "@angular/common";
import { Location } from '@angular/common';

import { environment } from 'src/environments/environment';


@Component({
  selector: "app-thankyou",
  templateUrl: "./thankyou.component.html",
  styleUrls: ["./thankyou.component.scss"],
})
export class ThankyouComponent implements OnInit {

	constructor(private fb: FormBuilder, public router: Router,private route: ActivatedRoute,private http: HttpClient,private location: Location) {	}
	
	ngOnInit(){	}
	
		loginAccount()
	  {
		this.router.navigate(['/auth/login']);	
	  }
}
