import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule, HttpLoaderFactory } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule,HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";
import { ChangePasswordComponent } from "./auth/changePassword/changePassword.component";
import { ChangePasswordSellerComponent } from "./auth/changePasswordSeller/changePasswordSeller.component";
import { ToastrModule } from "ngx-toastr";
import { DecimalPipe } from "@angular/common";
import { RegisterComponent } from "./auth/register/register.component";
import { SignupProcessComponent } from './auth/signupProcess/signupProcess.component';
import { SignupProcessSellerComponent } from './auth/signupProcessSeller/signupProcessSeller.component';
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { DataTablesModule} from 'angular-datatables';
import { NgxPhotoEditorModule} from "ngx-photo-editor";
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';

import {NgxImageCompressService} from 'ngx-image-compress';

import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgFor} from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { AdminGuard } from "./shared/guard/admin.guard";
import { CookieService } from "ngx-cookie-service";
import "hammerjs";
import "mousetrap";
import { SpinnerComponent } from './spinner/spinner.component';
//import { LoadingInterceptor } from './loading.interceptor';
@NgModule({
  declarations: [AppComponent, LoginComponent,RegisterComponent,ChangePasswordComponent,ChangePasswordSellerComponent,SignupProcessComponent,SignupProcessSellerComponent,SpinnerComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,		
    ToastrModule.forRoot(),
    HttpClientModule,
	//CKEditorModule,
	AngularEditorModule,
	DataTablesModule,
	NgxPhotoEditorModule,
	LightboxModule,
    GalleryModule,
	CdkDropList,
	NgFor,
	CdkDrag,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [  //{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
				AdminGuard, 
				CookieService, 
				DecimalPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
