import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import {
  S3Client,
  ListBucketsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
  SelectObjectContentCommand
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public FOLDER="Uploads";
  public filename;

  constructor() { }
  
  uploadFile(file) {
    const contentType = file.type;
    const bucket = new S3(
          {
              accessKeyId: 'AKIATEVG5RZOVA3GWEH3',
              secretAccessKey: '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',
              region: 'ap-south-1'
          }
      );
      const params = {
          Bucket: 'loraxgreenuploads',
          Key: this.FOLDER + file.name,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
      });
}

async checkR2(po_value)
{
	console.log(environment.R2endpoint);
	const S3 = new S3Client({
	  region: "auto",	  
	  endpoint: environment.R2endpoint,
	  credentials: {		
		accessKeyId: environment.R2accessKeyId,		
		secretAccessKey: environment.R2secretAccessKey,
	  },
	});
	
	
	
	console.log(
  await S3.send(
    new GetObjectCommand({Bucket: 'init-json', Key: 'moderna.json'})
  )
);
	
	
	var file=po_value+".json";	
	const ret= await getSignedUrl(S3, new GetObjectCommand({Bucket: 'init-json', Key: file}), { expiresIn: 3600 });		
	return ret;
}

	async upload(file,compnay_po_id)
	{			
	
		if(file.type=='image/jpeg')
		{
			this.filename = 'logo.jpg';
		}
		else if(file.type=='image/png')
		{
			this.filename = 'logo.png';
		}
		else if(file.type=='image/webp')
		{
			this.filename = 'logo.webp';
		}
		//else if(file.type=='image/jpeg')
		const S3 = new S3Client({
		  region: "auto",		  
		  endpoint: environment.R2endpoint,
		  credentials: {			
			accessKeyId: environment.R2accessKeyId,			
			secretAccessKey: environment.R2secretAccessKey,
		  },
		});						
		
		const command = new PutObjectCommand({
		Bucket: "home-images",
		Key: compnay_po_id+"/" + this.filename,
		Body: file,
		});
	  
		try 
		{
			const response = await S3.send(command);
			//console.log(this.filename);
			return this.filename;
		} catch (err) {
			console.error(err);
		}
		
	}
	
	async uploadAbout(file,compnay_po_id)
	{					
		if(file.type=='image/jpeg')
		{
			this.filename = 'about_us.jpg';
		}
		else if(file.type=='image/png')
		{
			this.filename = 'about_us.png';
		}
		else if(file.type=='image/webp')
		{
			this.filename = 'about_us.webp';
		}
		//else if(file.type=='image/jpeg')
		const S3 = new S3Client({
		  region: "auto",		  
		  endpoint: environment.R2endpoint,
		  credentials: {			
			accessKeyId: environment.R2accessKeyId,			
			secretAccessKey: environment.R2secretAccessKey,
		  },
		});		
		
		
		
		const command = new PutObjectCommand({
		Bucket: "home-images",
		Key: compnay_po_id+"/" + this.filename,
		Body: file,
		});
	  
		try 
		{
			const response = await S3.send(command);
			//console.log(this.filename);
			return this.filename;
		} catch (err) {
			console.error(err);
		}
		
	}
	
	
	async uploadBanner(file,compnay_po_id)
	{		
		
		if(file.type=='image/jpeg')
		{
			this.filename = uuidv4()+''+'.jpg';
		}
		else if(file.type=='image/png')
		{
			this.filename = uuidv4()+''+'.png';
		}
		else if(file.type=='image/webp')
		{
			this.filename = uuidv4()+''+'.webp';
		}
		//else if(file.type=='image/jpeg')
		const S3 = new S3Client({
		  region: "auto",		 
		  endpoint: environment.R2endpoint,
		  credentials: {			
			accessKeyId: environment.R2accessKeyId,			
			secretAccessKey: environment.R2secretAccessKey,
		  },
		});		
		
	
		
		const command = new PutObjectCommand({
		Bucket: "home-images",
		Key: compnay_po_id+"/" + this.filename,
		Body: file,
		});
	  
		try 
		{
			const response = await S3.send(command);			
			return this.filename;
		} catch (err) {
			console.error(err);
		}
		
	}


}

