<div class="page-wrapper" >
	<div class="authentication-main" >
		<div class="container-fluid" >
			<div class="row">
				<div class="col-xl-12 xl-100">
				  <div class="row">	  
					<div class="text-center" style="padding:20px;"><img src="assets/images/logo.png"   /></div>
					<div class="col-lg-12">
					  <div class="card">           
						<div class="card-body">          		                                
							<h1 style="text-align:center;">Thank you for Registering with us. <a (click)="loginAccount()" style="cursor:pointer;color:#4466f2">Please Login your Account.</a></h1>
						</div>
					  </div>		  
					</div>										
				  </div>
				</div>        
			</div>     
		</div>  
	</div>
</div>