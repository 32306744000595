import {Component, OnInit,ViewChild,ElementRef,VERSION } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl,FormArray } from '@angular/forms';
import { HttpClient,HttpHeaders,HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from "@angular/common";
import { Location } from '@angular/common';
import { UploadService } from './upload.service';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/loader.service';


@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {

	val:any;
	sub:any;
	customers:any;
	isShown: boolean = false ;	
	submitted: boolean = false;
	cmpisShown: boolean = false ;	
	cmpsubmitted: boolean = false;	
	poMessage: boolean;	
	poMessage1: boolean;	
	msg:any;
	cmpmsg:any;
	dst_date_from:any;
	dst_date_to:any;
	headers = new HttpHeaders({ 'Content-Type': 'application/json','API_KEY':'20e20m06i16e14r31p','Version':'1.0' });    	 
	options = { headers: this.headers };	
	username:any;
	password:any;
	token:any;
	opt:any;
	country:any;
	countryshort:any;
	
	public newUser = false;
	// public user: firebase.User;
	public loginForm: FormGroup;

	public errorMessage: any;
	
	
	public registerForm : FormGroup;	
	businessTypes: any;
	countries : any;
	countryProvinces: any;
	
	minDobString:any;
	maxDobString:any;
	statelong:any;
	@ViewChild("business_name") business_name: ElementRef;
	@ViewChild("business_subdomain") business_subdomain: ElementRef;
	@ViewChild("user_name") user_name: ElementRef;
	@ViewChild("cpw") cpw: ElementRef;
  	
	
	userDetails:any;
	selectedFiles: FileList;

	imgResult: string = '';


	constructor(private http: HttpClient,private route: ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private uploadService: UploadService,private loadingService: LoaderService) {
	
	} 
	
	

  ngOnInit() 
  {
		//env = environment;
		this.countryshort = environment.defaultCountryId;
		this.country = environment.defaultCountryId;
		
		let dat = new Date().toISOString();
		
		var yearMS = 365 * (1000 * 60 * 60 * 24); // 365 days
		var now = new Date().getTime();		
		var minDobMS = now - (122.5 * yearMS);		
		this.minDobString = new Date(minDobMS).toISOString().slice(0, 10);
		var eighteenYearsAgo = new Date();
		var eighteenYearsAgo1 = eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear()-18);
		this.maxDobString =new Date(eighteenYearsAgo1).toISOString().slice(0, 10);
		
  
		//this.username='Admin';
		//this.password='123456';
	  
	  
		
		
		this.registerForm = this.formBuilder.group({            					
			first_name: ['', Validators.required],
			last_name: [''],
			phone: ['', Validators.required],	
			username: ['', Validators.required],
			pw: ['', Validators.required],
			cpw: ['', Validators.required],
			//email: ['', [Validators.required, Validators.email]],
            //password: ['', [Validators.required, Validators.minLength(6)]]
        });
  
  
  }
  
  
	getPw(tr)
	{
				
		if(this.registerForm.controls['pw'].value!=this.registerForm.controls['cpw'].value)
		{
			alert("Password mismatch");
			this.registerForm.controls['cpw'].setValue("");		
			//this.pw1.nativeElement.focus();
		}
						
	  
	}
	
	
	
  
  	
	
	
	getUsername(tr)
	{
		
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			//alert(this.token.access_token);
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
		
			this.http.get(environment.api_url+'/v1/register/Username/'+tr.target.value,o).subscribe((response) => 
			{ 		
				//alert(response);
				if(Object.keys(response).length!=0)
				{
					alert("Username Already Exist");
					this.registerForm.controls['username'].setValue("");		
					this.user_name.nativeElement.focus();

				}
						
			});
		});					
	  
	}
	
	getState(tr)
	{		
	  this.statelong=tr.target.options[tr.target.options.selectedIndex].text;	  	 
	 
	}
	
	
	
  
  
  
  onSubmit()
	{
		
		this.submitted = true;
		if (this.registerForm.invalid) 
		{
			return;
		}
        						
		
		this.registerForm.value.created_at=new Date().toISOString();	

		alert(JSON.stringify(this.registerForm.value));	
		
		this.loadingService.setLoading(true);
		
		this.http.post(environment.api_url+'/auth/login', {"username": "john", "password": "changeme"},this.opt).subscribe((response) => 
		{
			this.token=response;
			let h = new HttpHeaders({ 'Content-Type': 'application/json','Authorization':'Bearer '+this.token.access_token,'Access-Control-Allow-Origin': "*" });    	 
			let o = { headers: h };
			
			this.http.post(environment.api_url+'/v1/register', this.registerForm.value,o).subscribe((response) => 
			{
				alert(JSON.stringify(response));
				this.loadingService.setLoading(false);
				this.router.navigate(['/auth/Thankyou']);								
			});			
						
		});
	}
  

}
