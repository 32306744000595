export const environment = {
	production: true,
	defaultCountryId:"IND",
	defaultCountry:"India",
	//api_url:"http://ec2-3-110-127-55.ap-south-1.compute.amazonaws.com:3000"
	//api_url:"http://127.0.0.1:3000", 
	api_url:"https://api.sunexl.com",
	R2endpoint: 'https://b5dd400326c5099765f9c7bcb5f29ddc.r2.cloudflarestorage.com',
	R2accessKeyId: '34ea63f60f0152775d24e548c102a2db',
	R2secretAccessKey: 'cc17cc5eb6c8d5ec2561863c8534c415d31826d7ff0ded9b0b66e45e0dd64e88'
};
