import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import {
  S3Client,
  ListBucketsCommand,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
  SelectObjectContentCommand
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public FOLDER="Uploads";

  constructor() { }
  
  uploadFile(file) {
    const contentType = file.type;
    const bucket = new S3(
          {
              accessKeyId: 'AKIATEVG5RZOVA3GWEH3',
              secretAccessKey: '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',
              region: 'ap-south-1'
          }
      );
      const params = {
          Bucket: 'loraxgreenuploads',
          Key: this.FOLDER + file.name,
          Body: file,
          ACL: 'public-read',
          ContentType: contentType
      };
      bucket.upload(params, function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
      });
//for upload progress   
/*bucket.upload(params).on('httpUploadProgress', function (evt) {
          console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
      }).send(function (err, data) {
          if (err) {
              console.log('There was an error uploading your file: ', err);
              return false;
          }
          console.log('Successfully uploaded file.', data);
          return true;
      });*/
}

async checkR2(po_value)
{

	const S3 = new S3Client({
	  region: "auto",
	  endpoint: 'https://b5dd400326c5099765f9c7bcb5f29ddc.r2.cloudflarestorage.com',
	  credentials: {
		accessKeyId: 'b50831ac710350babfa53bd99a26c7c3',
		secretAccessKey: '5185023473890d236c572330b865166efa5b3ab1b0bc9d277d1d3174b329fe21',
	  },
	});
	
	/*console.log(
  await S3.send(
    new GetObjectCommand({Bucket: 'init-json', Key: 'moderna.json'})
  )
);*/
	
	/*const S3 = new S3Client({
	  region: "auto",
	  endpoint: 'https://08d42f2fe575752e3a1b82ffa8dbe65b.r2.cloudflarestorage.com',
	  credentials: {
		accessKeyId: 'feca7e94935b097797a976e4bd0ae6bc',
		secretAccessKey: 'a485f40cc34a9341df1796e12b0e1464bed2209f0f688385f19406a4684db407',
	  },
	});*/
	var file=po_value+".json";
	console.log(file);	
	//const ret= await getSignedUrl(S3, new GetObjectCommand({Bucket: 'r2-uploads', Key: 'products.json'}), { expiresIn: 3600 });
	//console.log(await getSignedUrl(S3, new GetObjectCommand({Bucket: 'r2-uploads', Key: 'products.json'}), { expiresIn: 3600 }));		
	const ret= await getSignedUrl(S3, new GetObjectCommand({Bucket: 'init-json', Key: file}), { expiresIn: 3600 });
	//console.log(await getSignedUrl(S3, new GetObjectCommand({Bucket: 'init-json', Key: file}), { expiresIn: 3600 }));		
	
	
	
	return ret;
}


}
