import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?:any) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/master/Summary',title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false, 
		},
		{
			path: '/master/ViewExpense',title: 'Add Expense', icon: 'tag', type: 'link', badgeType: 'primary', active: false
		},
		{
			path: '/master/ViewPaymentAccount',title: 'Payment Account', icon: 'credit-card', type: 'link', badgeType: 'primary', active: false
		},
		{
			path: '/master/ViewExpenseAccount',title: 'Expense Account', icon: 'bar-chart', type: 'link', badgeType: 'primary', active: false
		},
		{
			title: 'Logout', icon: 'log-out', type: 'link', badgeType: 'primary',path: '/dashboard/Logout', active: false
		}
		
		
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
