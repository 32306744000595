import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { ChangePasswordComponent } from "./auth/changePassword/changePassword.component";
import { ChangePasswordSellerComponent } from "./auth/changePasswordSeller/changePasswordSeller.component";
import { ThankyouComponent } from "./auth/thankyou/thankyou.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ContentLayoutComponent } from "./shared/components/layout/content-layout/content-layout.component";
import { FullLayoutComponent } from "./shared/components/layout/full-layout/full-layout.component";
import { SignupProcessComponent } from './auth/signupProcess/signupProcess.component';
import { SignupProcessSellerComponent } from './auth/signupProcessSeller/signupProcessSeller.component';
import { content } from "./shared/routes/content-routes";
import { full } from "./shared/routes/full.routes";
import { AdminGuard } from "./shared/guard/admin.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/login",
    pathMatch: "full",
  },
  {
    path: "auth/login",
    component: LoginComponent,
  },
  {
    path: "auth/Register",
    component: RegisterComponent,
  },
  {
	  path: 'auth/SignupProcess',
	  component: SignupProcessComponent,          
  },
  {
	  path: 'auth/SignupProcessSeller',
	  component: SignupProcessSellerComponent,          
  },  
  {
    path: "auth/ChangePassword",
    component: ChangePasswordComponent,
  },
  {
    path: "auth/ChangePasswordSeller",
    component: ChangePasswordSellerComponent,
  },
  {
    path: "auth/Thankyou",
    component: ThankyouComponent,
  },  
  {
    path: "",
    component: ContentLayoutComponent,
    canActivate: [AdminGuard],
    children: content,
  },
  {
    path: "",
    component: FullLayoutComponent,
    canActivate: [AdminGuard],
    children: full,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
	  useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
