<style>
.example-list {
  width: 500px;
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.example-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

</style>
<!-- Container-fluid starts -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <!--<div class="card-header" style="padding:15px;background-color:#e5e5e5">
                    <h5>Add Product Details</h5>
                </div>-->
                <div class="card-body">			
					 <div class="row" >
					 <div class="u-pearls-xs row mb-12" style="padding-bottom:10px;">
						<div id="1" class="u-pearl current col-1" >
						  <div class="u-pearl-icon" >1</div><!--<span class="u-pearl-title">Page 1</span>-->
						</div>
						<div id="2" class="u-pearl done col-1">
						  <div class="u-pearl-icon">2</div><!--<span class="u-pearl-title">Page 2</span>-->
						</div>
						<div id="3" class="u-pearl done col-1">
						  <div class="u-pearl-icon">3</div><!--<span class="u-pearl-title">Page 3</span>-->
						</div>
						<div id="4" class="u-pearl done col-1">
						  <div class="u-pearl-icon">4</div><!--<span class="u-pearl-title">Page 4</span>-->
						</div>
						<div id="5" class="u-pearl done col-1">
						  <div class="u-pearl-icon">5</div><!--<span class="u-pearl-title">Page 5</span>-->
						</div>
						<div id="6" class="u-pearl done col-1">
						  <div class="u-pearl-icon">6</div><!--<span class="u-pearl-title">Page 6</span>-->
						</div>
						<div id="7" class="u-pearl done col-1">
						  <div class="u-pearl-icon">7</div><!--<span class="u-pearl-title">Page 7</span>-->
						</div>
						<div id="8" class="u-pearl done col-1">
						  <div class="u-pearl-icon">8</div><!--<span class="u-pearl-title">Page 8</span>-->
						</div>
						<div id="9" class="u-pearl done col-1">
						  <div class="u-pearl-icon">9</div><!--<span class="u-pearl-title">Page 9</span>-->
						</div>
						<div id="10" class="u-pearl done col-1">
						  <div class="u-pearl-icon">10</div><!--<span class="u-pearl-title">Page 10</span>-->
						</div>
						<div id="11" class="u-pearl done col-1">
						  <div class="u-pearl-icon">11</div><!--<span class="u-pearl-title">Page 10</span>-->
						</div>
						
					  </div>
						
					  
					 </div>
					
					
					<div class="row" id="form1" #form1>
						<form [formGroup]="pForm" class="needs-validation" novalidate>
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Add Product</h5>
							</div>
							<div class="card-body">
								<div class="row">
									
									<div class="col-md-3 mb-3">
										<label >Name</label>
										<input class="form-control btn-square" type="text" formControlName="prod_name" placeholder="" name="prod_name"  required [(ngModel)]="prod_name">
										<div *ngIf="pFormClicked && pForm.controls['prod_name'].errors"  >
											<div *ngIf="pForm.controls['prod_name'].errors?.['required']" class="text text-danger">Product Name is required</div>
										</div>
									</div>									
									<div class="col-md-3 mb-3">
										<label>Category 1</label>	
										<select class="form-control select btn-square " formControlName="category1_id" name="category1_id" placeholder="" required (change)="getCategory2($event);" [(ngModel)]="category1_id">	
											<option value="">Select</option>                                                                   
											<option *ngFor="let l1 of l1Categories" value={{l1.level1_id}}>{{l1.level1_name}}</option>                                                                   
										</select>	
											<div *ngIf="pFormClicked  && pForm.controls['category1_id'].errors" >	
												<div *ngIf="pForm.controls['category1_id'].errors?.['required']" class="text text-danger" >Category 1 is required</div>								
											</div>
									</div>
									<div class="col-md-3 mb-3">
										<label>Category 2</label>	
										<select class="form-control select btn-square " formControlName="category2_id" name="category2_id" placeholder="" (change)="getCategory3($event);" [(ngModel)]="category2_id">	
											<option value="">Select</option>                                                                   
											<option *ngFor="let l2 of l2Categories" value={{l2.level2_id}}>{{l2.level2_name}}</option>
										</select>												
									</div>
									<div class="col-md-3 mb-3">
										<label>Category 3</label>	
										<select class="form-control select btn-square " formControlName="category3_id" name="category3_id" placeholder="" [(ngModel)]="category3_id">	
											<option value="">Select</option>                                                                   
											<option *ngFor="let l3 of l3Categories" value={{l3.level3_id}}>{{l3.level3_name}}</option>
										</select>	
									</div>
									<div class="col-md-3 mb-3">
										<label>Brand</label>											
										<select class="form-control select btn-square " formControlName="prod_brand" name="prod_brand" placeholder="" [(ngModel)]="prod_brand">	
											<option value="">Select</option>                                                                   
											<option *ngFor="let b of brands" value={{b.brand_id}}>{{b.brand_name}}</option>
										</select>		
										<div style="text-align:right;"><a class="primary"(click)="openLg(content)" style="cursor:pointer;"> Add New </a></div>
									</div>
									<div class="col-md-3 mb-3">
										<label>Model</label>	
										<input class="form-control btn-square" type="text" formControlName="prod_model" placeholder="" name="prod_model"  [(ngModel)]="prod_model" >												
									</div>
									<div class="col-md-3 mb-3">
										<label >Colour</label>
										<input class="form-control btn-square" type="text" formControlName="prod_colour" placeholder="" [(ngModel)]="prod_colour"  >											
									</div>	
									<div class="col-md-3 mb-3">
										<label >Packing</label>
										<input class="form-control btn-square" type="text" formControlName="prod_pack" placeholder="" name="prod_pack" [(ngModel)]="prod_pack" >											
									</div>		
									<div class="col-md-12 mb-3">
										<label>Product Description</label>	
										<angular-editor formControlName="prod_description" [(ngModel)]="prod_description" [config]="editorConfig"></angular-editor>											
									</div>
									<div class="col-md-12 mb-3">
										<label>Product Specifications</label>	
										<angular-editor formControlName="prod_specifications" [(ngModel)]="prod_specifications" [config]="editorConfig"></angular-editor>											
									</div>
								</div>   																   																																				
							</div>
						</div>	
						<form [formGroup]="brandForm" class="needs-validation" novalidate>
						<ng-template #content let-modal>
							<div class="modal-header">
							  <h5 class="modal-title" id="modal-basic-title">Brand >> Add New</h5>
							  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">  </button>
							</div>
							
							<div class="modal-body">
								<div class="row">
									<div class="col-md-4 mb-3">
										<label>Brand Name</label>	
										<input class="form-control btn-square"  type="text" placeholder="" name="brand_name" formControlName="brand_name">
										<div *ngIf="onL1Clicked  && brandForm.controls['brand_name'].errors" >	
											<div *ngIf="brandForm.controls['brand_name'].errors?.['required']" class="text text-danger" > Brand Name is required</div>								
										</div>
									</div>									
								</div>											
							</div>
							
							<div class="modal-footer">
							  <button type="button" class="btn btn-outline-dark" (click)="onBrandModel()">Save</button>
							</div>
						  </ng-template>
						  </form>
						
																				
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(1)">Next</button>									
							</div>									
						</div>
						</form>
						</div>						
						
						
						<div class="row" id="form2" style="display:none" >
						<form [formGroup]="vForm" class="needs-validation" novalidate>
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Product Varient</h5>
							</div>
							<div class="card-body">								
								<div class="row">										
									<div class="col-md-12 mb-3">
										<label class="d-block" for="chk-ani2">
											<input class="prod_varient_enabled" id="chk-ani2" type="checkbox" formControlName="prod_varient_enabled" (click)="showVarient($event)" [(ngModel)]="prod_varient_enabled"> Allow Varient											
										</label>										
									</div>	
									<div class="col-md-3 mb-3" id="varient1" style="{{cls}}">
										<label >Varient Name</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="prod_varient_header" formControlName="prod_varient_header" [(ngModel)]="prod_varient_header">										
									</div>	
									<div class="col-md-3 mb-3" id="varient2" style="{{cls}}">
										<label >Display Type</label>
										<select class="form-control select btn-square " formControlName="prod_varient_display_type" name="prod_varient_display_type" placeholder="" [(ngModel)]="prod_varient_display_type">	
											<option value="">Select</option>                                                                   
											<option value="0">Round</option>
											<option value="1">Rectangle</option>
											<option value="2">List</option>
											<option value="3">Card</option>
										</select>	
									</div>								
								</div>
										
							</div>
						</div>	
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Tax Details</h5>
							</div>
							<div class="card-body">								
								<div class="row">										
									<div class="col-md-3 mb-3">
										<label >CGST</label>
										<input class="form-control btn-square" type="number"  placeholder="" name="cgst" formControlName="cgst" required [(ngModel)]="cgst">	
										<div *ngIf="vFormClicked  && vForm.controls['cgst'].errors" >	
											<div *ngIf="vForm.controls['cgst'].errors?.['required']" class="text text-danger" > CGST is required</div>								
										</div>	
									</div>
									<div class="col-md-3 mb-3">
										<label >SGST</label>
										<input class="form-control btn-square" type="number" placeholder="" name="sgst" formControlName="sgst" required [(ngModel)]="sgst">	
										<div *ngIf="vFormClicked  && vForm.controls['sgst'].errors" >	
											<div *ngIf="vForm.controls['sgst'].errors?.['required']" class="text text-danger" > SGST is required</div>								
										</div>								
									</div>
									<div class="col-md-3 mb-3">
										<label >IGST</label>
										<input class="form-control btn-square" type="number" placeholder="" name="igst" formControlName="igst"  [(ngModel)]="igst">										
										<div *ngIf="vFormClicked  && vForm.controls['igst'].errors" >	
											<div *ngIf="vForm.controls['igst'].errors?.['required']" class="text text-danger" > IGST is required</div>								
										</div>	
									</div>
																		
								</div>
										
							</div>
						</div>												
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(1)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(2)">Next</button>									
							</div>									
						</div>
						</form>
						</div>
						
						<div class="row" id="form3" style="display:none">    
						<form [formGroup]="uploadForm" class="needs-validation" novalidate>
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Product summary Image </h5>
							</div>
							<div class="card-body">
							<div class="row">
									<div class="col-md-3 mb-3">
										<label >Product Image</label>
										<input class="form-control btn-square" type="file" formControlName="summary_image_url" placeholder="" name="summary_image_url" (change)="selectFile($event)" >
										<!--<div *ngIf="logo_upload==''">
										<div *ngIf="next2Clicked  && cForm.controls['logo_upload'].errors" >	
											<div *ngIf="cForm.controls['logo_upload'].errors?.['required']" class="text text-danger" >Logo is required</div>								
										</div>
										</div>-->
									</div>	
									
								</div>
							</div>			
						</div>	
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(2)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(3)">Next</button>									
							</div>									
						</div>	
					</form>						
						</div> 
						
						<div class="row" id="form4" style="display:none">    
							<form [formGroup]="aboutForm" class="needs-validation" novalidate>
							<div class="card">
								<div class="card-header" style="padding:15px;background-color:#e5e5e5">
									<h5>About Us</h5>
								</div>
								<div class="card-body">
									<div class="row">							
										<div class="col-md-12 mb-3">
										<label >About us Text</label>
										<!--<ckeditor [editor]="editor" [config]="config" [data]="data2"></ckeditor>-->
										 <angular-editor formControlName="about_us_text" [(ngModel)]="about_us_text" [config]="editorConfig"></angular-editor>
										 <div *ngIf="next4Clicked  && aboutForm.controls['about_us_text'].errors" >	
											<div *ngIf="aboutForm.controls['about_us_text'].errors?.['required']" class="text text-danger" > About us Text is required</div>								
										</div>
										</div>
									</div>
									<div class="row">							
										<div class="col-md-3 mb-3">
												<label >About us Image</label>
												<input class="form-control btn-square" type="file" formControlName="about_us_image_url" placeholder="" name="about_us_image_url"  (change)="selectFileAbout($event)" >	<!--{{logo_upload}}-->
										</div>
									</div>
								</div>
							</div>	
							<div class="row">                            
									<div class="col-md-12 mb-3" style="text-align:right;">
										<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(3)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(4)">Next</button>									
									</div>									
							</div>
							</form>
						</div> 
						
						<div class="row" id="form5" style="display:none">    	
						<form [formGroup]="tcForm" class="needs-validation" novalidate>						
							<div class="card">
								<div class="card-header" style="padding:15px;background-color:#e5e5e5">
									<h5>General Terms and Conditions</h5>
								</div>
								<div class="card-body">
									<div class="row">							
										<div class="col-md-12 mb-3">
										<label >Terms and Condition</label>
										<angular-editor formControlName="terms_conditions" [(ngModel)]="terms_conditions" [config]="editorConfig"></angular-editor>
										 <div *ngIf="next5Clicked  && tcForm.controls['terms_conditions'].errors" >	
											<div *ngIf="tcForm.controls['terms_conditions'].errors?.['required']" class="text text-danger" > Terms and Condition is required</div>								
										</div>
										</div>
									</div>									
								</div>
							</div>	
							<div class="row">                            
									<div class="col-md-12 mb-3" style="text-align:right;">
										<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(4)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(5)">Next</button>									
									</div>									
							</div>	
						</form>							
						</div> 													
						<div class="row" id="form6" style="display:none;">    
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Level1 Category</h5>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12" style="padding-bottom:10px;">
									
										<a  class="btn btn-primary btn-sm" (click)="openLg(content)"> Add New </a>&nbsp;&nbsp;&nbsp;<!--<a  class="btn btn-primary btn-sm" (click)="openLg(content)"> Reorder </a>  -->
										
									</div>
								</div>
								<div class="table-responsive">                        											
									<table id="l1" class="table table-border-vertical" datatable >
									  <thead>
										<tr>
											<th >Category</th>
											<th >Description</th>				  											
										</tr>
									  </thead>
									  <tbody>
										<tr *ngFor="let l1 of l1Categories">
										  <td>{{l1.level1_name}}</td>
										  <td>{{l1.description}}</td>										  
										</tr>
									  </tbody>
									</table>						
								</div>
								
								</div>
						</div>	
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;padding:10px;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(5)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(6)">Next</button>									
							</div>									
						</div>						
						</div> 
						
						
							
						<div class="row" id="form7" style="display:none;">    
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Level2 Category</h5>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12" style="padding-bottom:10px;">
									<form [formGroup]="l2MCategoryForm" class="needs-validation" novalidate>
										<ng-template #content1 let-modal>
											<div class="modal-header">
											  <h5 class="modal-title" id="modal-basic-title">Level2 Category >> Add New</h5>
											  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">  </button>
											</div>
											<div class="modal-body">
											    <div class="row">
													<div class="col-md-4 mb-3">
														<label>Level1 Category</label>	
														<select class="form-control select btn-square "  name="level1_id" placeholder="" required formControlName="level1_id" >	
															<option value="">Select</option>	
															<option *ngFor="let l1 of l1Categories"  value={{l1.level1_id}}>{{l1.level1_name}}</option> 	
														</select>		
														<div *ngIf="onL2Clicked  && l2MCategoryForm.controls['level1_id'].errors" >	
														<div *ngIf="l2MCategoryForm.controls['level1_id'].errors?.['required']" class="text text-danger" > Category is required</div>								
													</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Level2 Category</label>	
														<input class="form-control btn-square"  type="text" placeholder="" name="level2_name" formControlName="level2_name">
														<div *ngIf="onL2Clicked  && l2MCategoryForm.controls['level2_name'].errors" >	
														<div *ngIf="l2MCategoryForm.controls['level2_name'].errors?.['required']" class="text text-danger" > Level2 Category is required</div>								
													</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Order Id</label>	
														<input class="form-control btn-square"  type="number"  placeholder="" name="addressline1" formControlName="level2_order_id">
														<div *ngIf="onL2Clicked  && l2MCategoryForm.controls['level2_order_id'].errors" >	
														<div *ngIf="l2MCategoryForm.controls['level2_order_id'].errors?.['required']" class="text text-danger" > Order ID is required</div>								
													</div>
													</div>
											</div>											
											</div>
											<div class="modal-footer">
											  <button type="button" class="btn btn-outline-dark" (click)="onL2Model()">Save</button>
											</div>
										  </ng-template>
										  </form>
										<a  class="btn btn-primary btn-sm" (click)="openLg(content1)"> Add New </a>
									</div>
								</div>
								<div class="table-responsive">                        											
									<table id="l2" class="table table-border-vertical" datatable >
									  <thead>
										<tr>
											<th >Level1 Category</th>
											<th >Level2 Category</th>				  											
										</tr>
									  </thead>
									  <tbody>
										<tr *ngFor="let l2 of l2Categories">										  
										  <td>{{l2.level1_name}}</td>		
										  <td>{{l2.level2_name}}</td>										  
										</tr>
									  </tbody>
									</table>						
								</div>
								
								</div>
						</div>		
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(6)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(7)">Next</button>	
							</div>									
						</div>
						</div>						
							
						<div class="row" id="form8" style="display:none;">    
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Level3 Category</h5>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12" style="padding-bottom:10px;">
										<form [formGroup]="l3MCategoryForm" class="needs-validation" novalidate>
										<ng-template #content3 let-modal>
											<div class="modal-header">
											  <h5 class="modal-title" id="modal-basic-title">Level3 Category >> Add New</h5>
											  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">  </button>
											</div>
											<div class="modal-body">
											    <div class="row">
													<div class="col-md-4 mb-3">
														<label>Level1 Category</label>	
														<select class="form-control select btn-square "  name="level1_id" formControlName="level1_id" placeholder="" required >	
															<option value="">Select</option>		
															<option *ngFor="let l1 of l1Categories"  value={{l1.level1_id}}>{{l1.level1_name}}</option> 		
														</select>	
														<div *ngIf="onL3Clicked  && l3MCategoryForm.controls['level1_id'].errors" >	
																<div *ngIf="l3MCategoryForm.controls['level1_id'].errors?.['required']" class="text text-danger" >Level1 Category is required</div>								
														</div>	
													</div>
													<div class="col-md-4 mb-3">
														<label>Level2 Category</label>	
														<select class="form-control select btn-square "  name="level2_id" placeholder="" required formControlName="level2_id">	
															<option value="">Select</option>	
																<option *ngFor="let l2 of l2Categories"  value={{l2.level2_id}}>{{l2.level2_name}}</option> 
														</select>		
														<div *ngIf="onL3Clicked  && l3MCategoryForm.controls['level2_id'].errors" >	
																<div *ngIf="l3MCategoryForm.controls['level2_id'].errors?.['required']" class="text text-danger" >Level2 Category is required</div>								
														</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Level3 Category</label>	
														<input class="form-control btn-square"  type="text" placeholder="" name="level3_name" formControlName="level3_name" required>
														<div *ngIf="onL3Clicked  && l3MCategoryForm.controls['level3_name'].errors" >	
															<div *ngIf="l3MCategoryForm.controls['level3_name'].errors?.['required']" class="text text-danger" >Level3 Category is required</div>								
														</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Order Id</label>	
														<input class="form-control btn-square"  type="number"  placeholder="" name="level3_order_id" formControlName="level3_order_id" required>
														<div *ngIf="onL3Clicked  && l3MCategoryForm.controls['level3_order_id'].errors" >	
																<div *ngIf="l3MCategoryForm.controls['level3_order_id'].errors?.['required']" class="text text-danger" >Level3 Category Order is required</div>								
														</div>
													</div>
												</div>											
											</div>
											<div class="modal-footer">
											  <button type="button" class="btn btn-outline-dark" (click)="onL3Model()">Save</button>
											</div>
										  </ng-template>
										  </form>
										<a  class="btn btn-primary btn-sm" (click)="openLg(content3)"> Add New </a>&nbsp;&nbsp;&nbsp;<!--<a  class="btn btn-primary btn-sm" (click)="openLg(content)"> Reorder </a>-->  
									</div>
								</div>
								<div class="table-responsive">                        											
									<table id="l3" class="table table-border-vertical" datatable >	
									  <thead>
										<tr>
											<th >Level1 Category</th>
											<th >Level2 Category</th>
											<th >Level3 Category</th>		
										</tr>
									  </thead>
									  <tbody>
										<tr *ngFor="let l3 of l3Categories">										  
										  <td>{{l3.level1_name}}</td>		
										  <td>{{l3.level2_name}}</td>										  
										  <td>{{l3.level3_name}}</td>										  
										</tr>
									  </tbody>
									</table>						
								</div>
								
								</div>
						</div>	
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(7)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(8)">Next</button>									
							</div>									
						</div>
						</div>													
						<div class="row" id="form9" style="display:none;">    
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Banner</h5>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12" style="padding-bottom:10px;">
										<form [formGroup]="MBannerForm" class="needs-validation" novalidate>
										<ng-template #content4 let-modal>
											<div class="modal-header">
											  <h5 class="modal-title" id="modal-basic-title">Level3 Category >> Add New</h5>
											  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">  </button>
											</div>
											<div class="modal-body">
											    <div class="row">
													<div class="col-md-4 mb-3">
														<label>Level1 Category</label>	
														<select class="form-control select btn-square "  formControlName="category1_id" placeholder="" required >	
															<option value="">Select</option>		
															<option *ngFor="let l1 of l1Categories"  value={{l1.level1_id}}>{{l1.level1_name}}</option> 		
														</select>	
														<div *ngIf="onBannerClicked  && MBannerForm.controls['category1_id'].errors" >	
																<div *ngIf="MBannerForm.controls['category1_id'].errors?.['required']" class="text text-danger" >Level1 Category is required</div>								
														</div>	
													</div>
													<div class="col-md-4 mb-3">
														<label>Level2 Category</label>	
														<select class="form-control select btn-square "  placeholder=""  formControlName="category2_id" >	
															<option value="">Select</option>	
																<option *ngFor="let l2 of l2Categories"  value={{l2.level2_id}}>{{l2.level2_name}}</option> 
														</select>		
														
													</div>
													<div class="col-md-4 mb-3">
														<label>Level3 Category</label>	
														<select class="form-control select btn-square "  placeholder=""  formControlName="category3_id" >	
															<option value="">Select</option>	
																<option *ngFor="let l3 of l3Categories"  value={{l3.level3_id}}>{{l3.level3_name}}</option> 
														</select>
														
													</div>
													<div class="col-md-4 mb-3">
														<label>Banner Image</label>	
														<input class="form-control btn-square"  type="file"  placeholder="" name="banner_url" formControlName="banner_url" (change)="selectFileBanner($event)">
														<div *ngIf="onBannerClicked  && MBannerForm.controls['banner_url'].errors" >	
																<div *ngIf="MBannerForm.controls['banner_url'].errors?.['required']" class="text text-danger" >Banner Image is required</div>								
														</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Banner Slogan</label>	
														<input class="form-control btn-square"  type="text"  placeholder="" name="banner_slogan" formControlName="banner_slogan">
														<div *ngIf="onBannerClicked  && MBannerForm.controls['banner_slogan'].errors" >	
																<div *ngIf="MBannerForm.controls['banner_slogan'].errors?.['required']" class="text text-danger" >Banner Slogan is required</div>								
														</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Banner Button Text</label>	
														<input class="form-control btn-square"  type="text"  placeholder="" name="banner_button_text" formControlName="banner_button_text">
														<div *ngIf="onBannerClicked  && MBannerForm.controls['banner_button_text'].errors" >	
															<div *ngIf="MBannerForm.controls['banner_button_text'].errors?.['required']" class="text text-danger" >Banner Button Text is required</div>								
														</div>
													</div>
													<div class="col-md-4 mb-3">
														<label>Order Id</label>	
														<input class="form-control btn-square"  type="number"  placeholder="" name="order_id" formControlName="order_id">
														<div *ngIf="onBannerClicked  && MBannerForm.controls['order_id'].errors" >	
																<div *ngIf="MBannerForm.controls['order_id'].errors?.['required']" class="text text-danger" >Order Id is required</div>								
														</div>
													</div>
											</div>									
											</div>
											<div class="modal-footer">
											  <button type="button" class="btn btn-outline-dark" (click)="onBannerModel()">Save</button>
											</div>
										  </ng-template>
										  </form>
										<a  class="btn btn-primary btn-sm" (click)="openLg(content4)"> Add New </a>&nbsp;&nbsp;&nbsp;<!--<a  class="btn btn-primary btn-sm" (click)="openLg(content)"> Reorder </a>-->  
									</div>
								</div>
								<div class="table-responsive">                        											
									<table  id="banner" class="table table-border-vertical" datatable >
									  <thead>
										<tr>
											<th >Banner Slogan</th>
											<th >Banner Button Text</th>
											<th >Banner</th>		
										</tr>
									  </thead>
									  <tbody>
										<tr *ngFor="let b of banners">										  
										  <td>{{b.banner_slogan}}</td>		
										  <td>{{b.banner_button_text}}</td>										  
										  <td>{{b.banner_url}}</td>										  
										</tr>
									  </tbody>
									</table>						
								</div>
								
								</div>
						</div>	
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(8)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(9)">Next</button>									
							</div>									
						</div>
						</div>																		
						<div class="row" id="form10" style="display:none;">    
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Collections</h5>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12" style="padding-bottom:10px;">
										<form [formGroup]="MCollectionForm" class="needs-validation" novalidate>
										<ng-template #content5 let-modal>
											<div class="modal-header">
											  <h5 class="modal-title" id="modal-basic-title">Level3 Category >> Add New</h5>
											  <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">  </button>
											</div>
											<div class="modal-body">
											    <div class="row">										
													<div class="col-md-12 mb-3">
														<label>Collection Name</label>	
														<input class="form-control btn-square"  type="text"  placeholder="" name="collection_name" formControlName="collection_name">
														<div *ngIf="onCollectionClicked  && MCollectionForm.controls['collection_name'].errors" >	
																<div *ngIf="MCollectionForm.controls['collection_name'].errors?.['required']" class="text text-danger" >Collection Name is required</div>								
														</div>
													</div>	
													<div class="col-md-12 mb-3">
														<label class="d-block" for="chk-ani2">
															<input class="collection_enable_select" id="chk-ani2" type="checkbox" formControlName="collection_enable_select"> Collection Enable Select
															
														</label>										
													</div>	
													<div class="col-md-12 mb-3">
														<label class="d-block" for="chk-ani2">
															<input class="collection_enable_display" id="chk-ani2" type="checkbox" formControlName="collection_enable_display"> Collection Enable Display
															
														</label>										
													</div>
												</div>								
											</div>
											<div class="modal-footer">
											  <button type="button" class="btn btn-outline-dark" (click)="onCollectionModel()">Save</button>
											</div>
										  </ng-template>
										  </form>
										<a  class="btn btn-primary btn-sm" (click)="openLg(content5)"> Add New </a>&nbsp;&nbsp;&nbsp;<!--<a  class="btn btn-primary btn-sm" (click)="openLg(content)"> Reorder </a>-->  
									</div>
								</div>
								<div class="table-responsive">                        											
									<table id="collection" class="table table-border-vertical" datatable >
									  <thead>
										<tr>
											<th >Collection Name</th>
											<th >Collection Enable Select</th>
											<th >Collection Enable Display</th>		
										</tr>
									  </thead>
									  <tbody>
										<tr *ngFor="let c of collections">										  
										  <td>{{c.collection_name}}</td>		
										  <td>{{c.collection_enable_select}}</td>										  
										  <td>{{c.collection_enable_display}}</td>										  
										</tr>
									  </tbody>
									</table>						
								</div>
								
								</div>
						</div>	
						<div class="row">                            
									<div class="col-md-12 mb-3" style="text-align:right;">
										<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(9)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="next(10)">Next</button>																		
									</div>									
								</div>
						</div>	
						<div class="row" id="form11" #form1 style="display:none;">
						<form [formGroup]="sellerForm" class="needs-validation" novalidate>
						<div class="card">							
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Seller Details</h5>
							</div>
							<div class="card-body">								
								<div class="row">
									<div class="col-md-3 mb-3">
										<label>Seller Type</label>	
										<select class="form-control select btn-square "  name="seller_type" placeholder="" formControlName="seller_type" required [(ngModel)]="seller_type" >	
											<option value="">Select</option>
											<option value="0">Individual</option>													
											<option value="1">Organization</option>													
										</select>	
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_type'].errors" >	
											<div *ngIf="sellerForm.controls['seller_type'].errors?.['required']" class="text text-danger" >Seller Type is required</div>								
										</div>
									</div>
									<div class="col-md-3 mb-3">
										<label>Type</label>	
										<select class="form-control select btn-square "  name="seller_business_type" placeholder="" formControlName="seller_business_type" required [(ngModel)]="seller_business_type" [disabled]=true>	
											<option value="">Select</option>
											<option value="0">Individual</option>													
											<option value="1">Organization</option>													
										</select>	
											<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_business_type'].errors" >	
												<div *ngIf="sellerForm.controls['seller_business_type'].errors?.['required']" class="text text-danger" >Business Type is required</div>								
											</div>
									</div>
									<div class="col-md-3 mb-3">
										<label >Business Name</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_business_name" formControlName="seller_business_name" required [(ngModel)]="seller_business_name" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_business_name'].errors" >	
												<div *ngIf="sellerForm.controls['seller_business_name'].errors?.['required']" class="text text-danger" >Business Name is required</div>								
											</div>
									</div>									
									<div class="col-md-3 mb-3">
										<label >Legal Name</label>
										<input class="form-control btn-square" type="text" placeholder="" name="seller_legal_name" formControlName="seller_legal_name" required [(ngModel)]="seller_legal_name" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_legal_name'].errors" >	
												<div *ngIf="sellerForm.controls['seller_legal_name'].errors?.['required']" class="text text-danger" >Legal Name is required</div>								
											</div>
									</div>
																										
								</div>                        
								   
								<div class="row">                            
									<div class="col-md-3 mb-3">
										<label >Registration Number</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_registration_number" formControlName="seller_registration_number"  [(ngModel)]="seller_registration_number" >
										
									</div>									
									<div class="col-md-3 mb-3">
										<label >Registration Date</label>
										<input class="form-control btn-square" type="date" placeholder="" name="seller_registration_date" formControlName="seller_registration_date"  [(ngModel)]="seller_registration_date" >
										
									</div>	
									<div class="col-md-3 mb-3">
										<label >PAN Number</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_pan_number" formControlName="seller_pan_number" [(ngModel)]="seller_pan_number" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_pan_number'].errors" >	
											<div *ngIf="sellerForm.controls['seller_pan_number'].errors?.['required']" class="text text-danger" >Pan Number is required</div>								
										</div>
									</div>	
									<div class="col-md-3 mb-3">
										<label >TAN Number</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_tan_number" formControlName="seller_tan_number"   [(ngModel)]="seller_tan_number" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_tan_number'].errors" >	
											<div *ngIf="sellerForm.controls['seller_tan_number'].errors?.['required']" class="text text-danger" >Tan Number is required</div>								
										</div>
									</div>
									<div class="col-md-3 mb-3">
										<label >GSTIN Number</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_gstin_number" formControlName="seller_gstin_number" [(ngModel)]="seller_gstin_number" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_gstin_number'].errors" >	
											<div *ngIf="sellerForm.controls['seller_gstin_number'].errors?.['required']" class="text text-danger" >GSTIN Number is required</div>								
										</div>
									</div>		
									<div class="col-md-3 mb-3">
										<label >Commission Persentage</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_comm_percentage" formControlName="seller_comm_percentage" [(ngModel)]="seller_comm_percentage" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_comm_percentage'].errors" >	
											<div *ngIf="sellerForm.controls['seller_comm_percentage'].errors?.['required']" class="text text-danger" >Commission Persentage is required</div>								
										</div>
									</div>	
									<div class="col-md-3 mb-3">
										<label >Maximum Product</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_maximum_products" formControlName="seller_maximum_products" [(ngModel)]="seller_maximum_products" >
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_maximum_products'].errors" >	
											<div *ngIf="sellerForm.controls['seller_maximum_products'].errors?.['required']" class="text text-danger" >Maximum Products is required</div>								
										</div>
									</div>
									<div class="col-md-3 mb-3">
										<label >Office Phone</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="seller_phone" formControlName="seller_phone" required [(ngModel)]="seller_phone">	
										<div *ngIf="sellerSubmitted  && sellerForm.controls['seller_phone'].errors" >	
											<div *ngIf="sellerForm.controls['seller_phone'].errors?.['required']" class="text text-danger" > Office Phone is required</div>								
										</div>	
									</div>
									<div class="col-md-3 mb-3">
										<label >Office Email</label>
										<input class="form-control btn-square" type="text" placeholder="" name="seller_office_email" formControlName="seller_office_email" required [(ngModel)]="seller_office_email">	
										<div *ngIf="sellerSubmitted"  >
											<div *ngIf="sellerForm.controls['seller_office_email'].errors?.['required']" class="text text-danger">Office Email address is required</div>
											<div *ngIf="sellerForm.controls['seller_office_email'].errors?.['pattern']" class="text text-danger" >Office Email is Invalid</div>
										</div>										
									</div>																
									<div class="col-md-3 mb-3">
										<label >Website</label>
										<input class="form-control btn-square" type="text" placeholder="" name="seller_web_url" formControlName="seller_web_url" [(ngModel)]="seller_web_url">										
									</div>									
									<div class="col-md-3 mb-3">
										<label >Insta</label>
										<input class="form-control btn-square" type="text" placeholder="" name="seller_insta" formControlName="seller_insta" [(ngModel)]="seller_insta">										
									</div>
									<div class="col-md-3 mb-3">
										<label >LinkedIn</label>
										<input class="form-control btn-square" type="text" placeholder="" name="seller_linkedin" formControlName="seller_linkedin" [(ngModel)]="seller_linkedin">										
									</div>	
								</div> 
							</div>
						</div>	
						
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5>Address</h5>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-md-3 mb-3">
										<label>Address Line1</label>	
										<input class="form-control btn-square"  type="text" formControlName="seller_addressline1" placeholder="" name="seller_addressline1" [(ngModel)]="seller_addressline1">
									</div>
									<div class="col-md-3 mb-3">
										<label>Address Line2</label>	
										<input class="form-control btn-square"  type="text" formControlName="seller_addressline2" placeholder="" name="seller_addressline2" [(ngModel)]="seller_addressline2">
									</div>
									<div class="col-md-3 mb-3">
										<label >Street No</label>
									   <input class="form-control btn-square"  type="text" formControlName="seller_street_no" placeholder="" name="seller_street_no" [(ngModel)]="seller_street_no"> 	
									</div>
									<div class="col-md-3 mb-3">
										<label >Street Name</label>
										<input class="form-control btn-square"  type="text" formControlName="seller_street_name" placeholder="" name="seller_street_name" [(ngModel)]="seller_street_name">                                
									</div>									
									<div class="col-md-3 mb-3">
										<label >Area</label>
										<input class="form-control btn-square" type="text" formControlName="seller_area_name" name="seller_area_name" placeholder="" required [(ngModel)]="seller_area_name">											
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_area_name'].errors"  >
											<div *ngIf="sellerForm.controls['seller_area_name'].errors?.['required']" class="text text-danger">Area is Required</div>
										</div>	
									</div>
									<div class="col-md-3 mb-3">
										<label >Zip</label>
										<input class="form-control btn-square" type="text" formControlName="seller_zip" placeholder="" name="seller_zip" required [(ngModel)]="seller_zip">                               
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_zip'].errors"  >
											<div *ngIf="sellerForm.controls['seller_zip'].errors?.['required']" class="text text-danger">Zip is Required</div>
										</div>	
									</div>
									<div class="col-md-3 mb-3">
										<label >Region</label>
										<input class="form-control btn-square" type="text" formControlName="seller_region_name" name="seller_region_name" placeholder="" [(ngModel)]="seller_region_name">	 
									</div>
									<div class="col-md-3 mb-3">
										<label >Province</label>
										<select class="form-control btn-square" formControlName="seller_stateshort" name="seller_stateshort" placeholder=""  required (change)="getState($event);" [(ngModel)]="seller_stateshort">	
											<option value="">Select</option>                                                                   
											<option *ngFor="let state of countryProvinces"  value={{state.shortname}}>{{state.longname}}</option> 
										</select>	 
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_stateshort'].errors"  >
											<div *ngIf="sellerForm.controls['seller_stateshort'].errors?.['required']" class="text text-danger" >Province is required</div>
										</div>	
									</div>
									<div class="col-md-3 mb-3">
										<label >Country</label>
										<input class="form-control btn-square" type="text"  placeholder="" name="country" value={{country}}   [disabled]=true>                                
									</div>
								</div>
							</div>			
						</div>
						<div class="card">
							<div class="card-header" style="padding:15px;background-color:#e5e5e5">
								<h5 >Point of Contact</h5>
							</div>
							<div class="card-body">
								<div class="row">
									
									<div class="col-md-4 mb-3">
										<label >First Name</label>
										<input class="form-control btn-square" type="text" formControlName="seller_admin_first_name" placeholder="" name="seller_admin_first_name" required [(ngModel)]="seller_admin_first_name"> 
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_admin_first_name'].errors">
											<div *ngIf="sellerForm.controls['seller_admin_first_name'].errors?.['required']" class="text text-danger" >First Name is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Last Name</label>
										<input class="form-control btn-square" type="text" formControlName="seller_admin_last_name" placeholder="" name="seller_admin_last_name" [(ngModel)]="seller_admin_last_name">
									</div>
									<div class="col-md-4 mb-3">
										<label >Title</label>
										<input class="form-control btn-square" type="text" formControlName="seller_admin_title" placeholder="" name="seller_admin_title"  required [(ngModel)]="seller_admin_title">
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_admin_title'].errors" >
											<div *ngIf="sellerForm.controls['seller_admin_title'].errors?.['required']" class="text text-danger"  >Title is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Gender</label>
										<select class="form-control btn-square" formControlName="seller_admin_gender" name="seller_admin_gender" placeholder="" required [(ngModel)]="seller_admin_gender">	
											<option value="">Select</option>                                                                   
											<option value="1">Male</option>                                                                   
											<option value="2">Female</option>                                                                   
											<option value="3">Others</option>                                                                   						
										</select>
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_admin_gender'].errors"  >
											<div *ngIf="sellerForm.controls['seller_admin_gender'].errors?.['required']" class="text text-danger" >Gender is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Date of Birth</label>
										<input class="form-control btn-square" type="date" formControlName="seller_admin_dob" placeholder="" name="seller_admin_dob"  min="{{minDobString}}" max="{{maxDobString}}" required [(ngModel)]="seller_admin_dob">
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_admin_dob'].errors"  >
											<div *ngIf="sellerForm.controls['seller_admin_dob'].errors?.['required']" class="text text-danger">Date of birth is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Phone</label>
										<input class="form-control btn-square" type="number" formControlName="seller_admin_phone" placeholder="" name="seller_admin_phone"  required [(ngModel)]="seller_admin_phone">
										<div *ngIf="sellerSubmitted && sellerForm.controls['seller_admin_phone'].errors"  >
											<div *ngIf="sellerForm.controls['seller_admin_phone'].errors?.['required']" class="text text-danger" >Phone is required</div>
										</div>
									</div>
									<div class="col-md-4 mb-3">
										<label >Email ID</label>
										<input class="form-control btn-square" type="email" formControlName="seller_admin_email" placeholder="" name="seller_admin_email"  required [(ngModel)]="seller_admin_email">
										<div *ngIf="sellerSubmitted"  >
											<div *ngIf="sellerForm.controls['seller_admin_email'].errors?.['required']" class="text text-danger">Email address is required</div>
											<div *ngIf="sellerForm.controls['seller_admin_email'].errors?.['pattern']" class="text text-danger" >Email is Invalid</div>
										</div>
									</div>									
								</div>
							</div>													
						</div>		
						<div class="row">                            
							<div class="col-md-12 mb-3" style="text-align:right;">
								<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="back(10)">Back</button>&nbsp;&nbsp;<button class="btn btn-primary btn-sm" type="button" placement="top" ngbTooltip="Next" (click)="onSeller()">Save</button>									
							</div>									
						</div>
						</form>
						</div>	
						
						
						
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends -->