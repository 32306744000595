<div class="page-wrapper" >
<div class="authentication-main" >
<div class="authentication-box">
  <div class="row">
    <div class="col-xl-12 xl-100">
      <div class="row">	  
	   <div class="text-center" style="padding:20px;"><img src="assets/images/logo.png"   /></div>	
	   <div class="text-center" style="padding:20px;" *ngIf="actMsg">{{registerMsg}}</div>	
        <div class="col-lg-12">
          <div class="card mt-4">
            <div class="card-header" style="padding:15px;background-color:#e5e5e5">                
			<h5>Sign In</h5> 
		</div>
        <div class="card-body">          		                                
          <form class="needs-validation" [formGroup]="loginForm" *ngIf="!newUser" novalidate>
		  <div *ngIf="isShown" style="color:red;" >{{msg}}</div>
            <div class="form-group">
              <label class="col-form-label pt-0">Username</label>
               <input class="form-control" type="text" formControlName="username" required [ngModel]="username"/>
				<div *ngIf="submitted && loginForm.controls['username'].errors" >
					<div *ngIf="loginForm.controls['username'].errors?.['required']" class="text text-danger" >Username is required</div>
				</div>
              
            </div>
            <div class="form-group">
              <label class="col-form-label">Password</label>
              <input class="form-control" type="password" formControlName="pw" required [ngModel]="pw"/>
				<div *ngIf="submitted && loginForm.controls['pw'].errors" >
					<div *ngIf="loginForm.controls['pw'].errors?.['required']" class="text text-danger" >Password is required</div>
				</div>
            </div>
            
              
            <label for="checkbox1"><a (click)="createAccount()" style="cursor:pointer;color:primary">Create Account</a></label>
            
            <div class="form-group row mt-3 mb-0">
			<!--[disabled]="!loginForm.valid"-->
              <button class="btn btn-secondary btn-block"  (click)="login()" type="submit">
                <span>Sign In</span>
              </button>
            </div>
            <!--<div class="login-divider"></div>-->
            <!--<div class="social mt-3">
              <div class="form-group btn-showcase d-flex">
                 <button class="btn social-btn btn-fb d-inline-block" (click)="loginFacebook()">
                  <i class="fa fa-facebook"></i>
                </button>
                <button class="btn social-btn btn-twitter d-inline-block" (click)="loginTwitter()">
                  <i class="fa fa-twitter"></i>
                </button>
                <button class="btn social-btn btn-google d-inline-block" (click)="loginGoogle()">
                  <i class="fa fa-google"></i>
                </button> 
              </div>
            </div>-->
          </form>
        </div>
						 
			
          </div>
        </div>						
      </div>
    </div>        
 </div>   
  
  
  
  
  
  
  </div>

  
</div>

</div>